import React from "react";
import {
  FormControl,
  Select,
  MenuItem,
  SelectProps,
  FormLabel,
  Typography,
  FormHelperText,
  Stack,
} from "@mui/material";
import { useDetectMobile } from "../hooks/useDetectMobile";

export type SelectFieldProps = SelectProps & {
  label: string;
  options: Array<{
    value: string | number;
    label: React.ReactNode;
    error?: boolean;
    helperText?: string;
  }>;
  helperText?: string;
  labelHelperText?: string;
  fullWidth?: boolean;
};

const SelectField = React.forwardRef<HTMLInputElement, SelectFieldProps>(
  (
    {
      label,
      options,
      error,
      helperText,
      labelHelperText,
      fullWidth = true,
      ...props
    },
    ref
  ) => {
    const isMobile = useDetectMobile();
    return (
      <FormControl fullWidth={fullWidth} size="small">
        {label ? (
          <FormLabel component="legend" required={props.required}>
            <Typography component="span" variant="inherit">
              {label}
            </Typography>
            {labelHelperText && (
              <Typography component="span" color={error ? "error" : undefined}>
                {" "}
                {labelHelperText}
              </Typography>
            )}
          </FormLabel>
        ) : null}
        {isMobile ? (
          // Native <select> for mobile
          <select
            onChange={(e) => {
              if (props.onChange) {
                props.onChange(e as any, null);
              }
            }}
            value={props.value as string | number | undefined}
            style={{
              width: "100%",
              padding: "8px",
              borderRadius: "8px",
              border: error ? "1px solid red" : "1px solid #ccc",
              fontSize: "1rem",
              backgroundColor: "white",
              appearance: "none",
              color: "black",
            }}
          >
            {props.value ? null : <option key="b0" value="" disabled={true}>Enter Options</option>}
            {options.map((option, index) => (
              <option key={index} value={option.value}>
                {option.label}
              </option>
            ))}
          </select>
          ) : (
          <Select
            inputRef={ref}
            displayEmpty={!!props.placeholder}
            error={error}
            renderValue={
              props.value != null && props.value !== ""
                ? props.renderValue
                : () => (
                    <Typography sx={{ opacity: 0.42 }}>
                      {props.placeholder}
                    </Typography>
                  )
            }
            fullWidth={fullWidth}
            {...props}
          >
            {options.map((option, index) => (
              <MenuItem key={index} value={option.value}>
                <Stack direction="row" spacing={1}>
                  <Typography>{option.label}</Typography>
                  {option.helperText && (
                    <Typography color={option.error ? "error" : undefined}>
                      {option.helperText}
                    </Typography>
                  )}
                </Stack>
              </MenuItem>
            ))}
          </Select>
        )}
        {helperText ? (
          <FormHelperText error={error}>{helperText}</FormHelperText>
        ) : null}
      </FormControl>
    );
  }
);

export default SelectField;
