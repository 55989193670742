import React, { useState } from "react";
import { MoreVert } from "@mui/icons-material";
import { IconButton, Menu, MenuItem, Typography } from "@mui/material";
import { useDetectMobile } from "../hooks/useDetectMobile";
import { Modal } from "./Modal";

export type MoreMenuButtonProps = {
  disabled?: boolean;
  component?: React.ElementType;
  componentProps?: Record<string, any>;
  menuItems: Array<{
    label: string;
    disabled?: boolean;
    onClick?: () => void;
  }>;
};

export const MoreMenuButton: React.FC<MoreMenuButtonProps> = ({
  disabled,
  menuItems,
  component = IconButton,
  componentProps = {},
}) => {
  const isMobile = useDetectMobile();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleMenuClose = () => {
    setAnchorEl(null);
  };
  const handleMenuItemClose = (
    event: React.MouseEvent<HTMLLIElement>,
    onClick?: () => void
  ) => {
    setIsModalOpen(false);
    setAnchorEl(null);
    if (onClick) {
      event.stopPropagation();
      onClick();
    }
  };

  const handleMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    // Allows for the button to be pressed inside a table that has a row click event
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
    setIsModalOpen(true);
  };

  return (
    <>
      {React.createElement(component, {
        disabled,
        onClick: handleMenuClick,
        ...componentProps,
        children: <MoreVert />,
      })}
      {isMobile && isModalOpen && (
        <Modal isOpen={true} onClose={() => setIsModalOpen(false)} ignoreViewport={isMobile}>
          {menuItems.map((item, index) => (
            <MenuItem
              disabled={item.disabled}
              key={index}
              onClick={(e) => handleMenuItemClose(e, item?.onClick)}
            >
              <Typography fontSize="2em">{item.label}</Typography>
            </MenuItem>
          ))}
        </Modal>
      )}
      {!isMobile && (
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleMenuClose}
        >
          {menuItems.map((item, index) => (
            <MenuItem
              disabled={item.disabled}
              key={index}
              onClick={(e) => handleMenuItemClose(e, item?.onClick)}
            >
              {item.label}
            </MenuItem>
          ))}
        </Menu>
      )}
    </>
  );
};
