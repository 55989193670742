export const optionRoom = [
  "Master Bedroom",
  "Bedroom 2",
  "Bedroom 3",
  "Bedroom 4",
  "Bedroom 5",
  "Master Bathroom",
  "Bathroom 2",
  "Bathroom 3",
  "Half-Bath",
  "Utility Room",
  "Kitchen",
  "Dining Room",
  "Pantry",
  "Living Room",
  "Family Room",
  "Den",
  "Foyer",
  "Hallway",
  "Front Porch",
  "Back Porch",
  "Office",
  "Throughout Home",
  "Non-Room Option",
] as const;

export type OptionRoom = (typeof optionRoom)[number];

export const getOptionRoomOptions = (optionRooms: OptionRoom[]) => {
  return optionRooms.map((m) => ({
    label: m,
    value: m,
  }));
};

export const roomOptions = getOptionRoomOptions(Object.values(optionRoom));

export const isOptionRoomNonRoomOption = (optionRoom: OptionRoom) => {
  return optionRoom === "Non-Room Option";
};
