export const modelRoom = {
  masterBedroom: "Master Bedroom",
  bedroom2: "Bedroom 2",
  bedroom3: "Bedroom 3",
  bedroom4: "Bedroom 4",
  bedroom5: "Bedroom 5",
  masterBathroom: "Master Bathroom",
  bathroom2: "Bathroom 2",
  bathroom3: "Bathroom 3",
  halfBath: "Half-Bath",
  utilityRoom: "Utility Room",
  kitchen: "Kitchen",
  diningRoom: "Dining Room",
  pantry: "Pantry",
  livingRoom: "Living Room",
  familyRoom: "Family Room",
  den: "Den",
  foyer: "Foyer",
  hallway: "Hallway",
  frontPorch: "Front Porch",
  backPorch: "Back Porch",
  office: "Office",
  throughoutHome: "Throughout Home"
} as const;

export type ModelRoom = (typeof modelRoom)[keyof typeof modelRoom];

export const modelRoomOptions = Object.values(modelRoom).map((m) => ({
  label: m,
  value: m,
}));

const bedrooms: ModelRoom[] = [
  modelRoom.masterBedroom,
  modelRoom.bedroom2,
  modelRoom.bedroom3,
  modelRoom.bedroom4,
  modelRoom.bedroom5,
];

const bathrooms: ModelRoom[] = [
  modelRoom.masterBathroom,
  modelRoom.bathroom2,
  modelRoom.bathroom3,
];

const halfBathrooms: ModelRoom[] = [
  modelRoom.halfBath
];

export const getNumBedrooms = (modelRooms: ModelRoom[]) => {
  return modelRooms.filter((room) => bedrooms.includes(room)).length;
};

export const getNumBathrooms = (modelRooms: ModelRoom[]) => {
  return modelRooms.filter((room) => bathrooms.includes(room)).length;
};

export const getNumHalfBathrooms = (modelRooms: ModelRoom[]) => {
  return modelRooms.filter((room) => halfBathrooms.includes(room)).length;
};