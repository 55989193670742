import { useCallback, useEffect, useMemo, useState } from "react";
import {
  Avatar,
  Box,
  Button,
  Card,
  CardActionArea,
  Stack,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import { Modal } from "../../components/Modal";
import { useFieldArray, useForm } from "react-hook-form";
import {
  DescriptionOutlined,
  FileDownloadOutlined,
  PrintOutlined,
} from "@mui/icons-material";
import { useApiRequest } from "../../hooks/useApiRequest";
import { Order, OrderStatus } from "../../types/order";
import CheckboxField from "../../components/CheckboxField";
import { downloadFile, printFile } from "../../utils/file";
import { getTimezone } from "../../utils/date";
import { useDetectMobile } from "../../hooks/useDetectMobile";
import { useSession } from "../../hooks/useSession";

type DocumentDownloadModalProps = {
  order: Order;
  onClose: () => void;
};

type FormData = {
  printAllDocuments: number;
  printDocuments: Array<{
    label: string;
    field: string;
    value: number;
    enabledFor: readonly OrderStatus[];
  }>;
  downloadDocuments: Array<{
    label: string;
    field: string;
    value: boolean;
    enabledFor: readonly OrderStatus[];
  }>;
};

export const DocumentModal: React.FC<DocumentDownloadModalProps> = ({
  order,
  onClose,
}) => {
  const isMobile = useDetectMobile();
  const [isPrintView, setIsPrintView] = useState(!isMobile);
  const [isProcessing, setIsProcessing] = useState(false);
  const [downloadAllDocuments, setDownloadAllDocuments] = useState(false);

  const { user } = useSession();

  const defaultDocuments = useMemo(
    () => {
      const so = 
        {
          label: "Sales Order",
          field: "so",
          enabledFor: ["q", "o", "f", "i", "c", "v", "s", "a"] as const,
        };
      const sowp =
        {
          label: "Sales Order w/ Pricing",
          field: "sp",
          enabledFor: ["q", "o", "f", "i", "c", "v", "s", "a"] as const,
        };
      const wo =
        {
          label: "Work Order",
          field: "wo",
          enabledFor: ["o", "f", "i", "c", "v", "s", "a"] as const,
        };
      const dp =
        {
          label: "Dataplate",
          field: "dp",
          enabledFor: ["i", "c", "v", "s", "a"] as const,
        };
      const inv =
        {
          label: "Invoice",
          field: "in",
          enabledFor: ["i", "c", "v", "s", "a"] as const,
        };
      const ci =
        {
          label: "Consumer Information",
          field: "ci",
          enabledFor: ["i", "c", "v", "s", "a"] as const,
        };
      const co =
        {
          label: "Certificate of Origin",
          field: "co",
          enabledFor: ["i", "c", "v", "s", "a"] as const,
        };
      if (user?.isBuyerMode) {
        return [
          so,sowp,dp,ci,co
        ];
      } else if (user?.isRetailUser) {
        return [
          so,sowp,dp,inv,ci,co
        ];
      } else {
        return [
          so,sowp,wo,dp,inv,ci,co
        ]
      }
    },
    [user?.isBuyerMode, user?.isRetailUser]
  );

  const defaultPrintDocuments: FormData["printDocuments"] = useMemo(
    () => defaultDocuments.map((doc) => ({ ...doc, value: 0 })),
    [defaultDocuments]
  );

  const defaultDownloadDocuments: FormData["downloadDocuments"] = useMemo(
    () => defaultDocuments.map((doc) => ({ ...doc, value: false })),
    [defaultDocuments]
  );

  const {
    register,
    control,
    watch,
    setValue,
    formState: { isValid },
  } = useForm<FormData>({
    reValidateMode: "onChange",
    mode: "onChange",
    defaultValues: {
      printAllDocuments: 0,
      printDocuments: defaultPrintDocuments,
      downloadDocuments: defaultDownloadDocuments,
    },
  });

  const { fields } = useFieldArray({
    control,
    name: "printDocuments",
  });

  const printAllDocuments = watch("printAllDocuments");
  const printDocuments = watch("printDocuments");
  const downloadDocuments = watch("downloadDocuments");

  const {
    data: tokenData,
    request: tokenRequest,
    loading: tokenLoading,
  } = useApiRequest<{ token: string; expires: number }>();

  const {
    data: downloadedBlob,
    request: blobRequest,
    loading: blobLoading,
    response: blobResponse,
  } = useApiRequest<string>();

  useEffect(() => {
    tokenRequest("/documents/authtoken", {
      method: "GET",
    });
  }, [tokenRequest]);

  const handlePrint = () => {
    const data = isPrintView
      ? printDocuments.reduce((acc, field) => {
          return isFieldDisabled(field)
            ? `${acc}&${field.field}=${field.value}`
            : acc;
        }, ``)
      : downloadDocuments.reduce((acc, field) => {
          return isFieldDisabled(field)
            ? `${acc}&${field.field}=${field.value ? "1" : "0"}`
            : acc;
        }, ``);

    const token = tokenData?.token;
    const action = isPrintView ? "p" : "d";
    const tz = getTimezone();

    blobRequest(
      `orders/${order.orderGuid}/documents?token=${token}&action=${action}&tz=${tz}${data}`,
      {
        method: "GET",
        responseType: "blob",
      }
    );
    setIsProcessing(true);
  };

  const handleSingleDownload = (field: string) => {
    const token = tokenData?.token;
    const tz = getTimezone();
    window.open(
      `${process.env.REACT_APP_API_BASE_URL}/orders/${order.orderGuid}/documents?token=${token}&action=d&tz=${tz}&${field}=1`,
      "_blank"
    );
    setIsProcessing(true);
  };

  const hasPendingItems = isPrintView
    ? printDocuments.filter((doc) => doc.value > 0).length !== 0
    : downloadDocuments.filter((doc) => doc.value).length !== 0;

  // Update value of all fields when printAllDocuments changes
  useEffect(() => {
    if (printAllDocuments === 0) return;
    for (let i = 0; i < defaultPrintDocuments.length; i++) {
      if (defaultPrintDocuments[i].enabledFor.includes(order.orderStatus)) {
        setValue(`printDocuments.${i}.value`, printAllDocuments);
      }
    }
  }, [printAllDocuments, defaultPrintDocuments, setValue, order.orderStatus]);

  // Update value of all fields when downloadAllDocuments changes
  useEffect(() => {
    for (let i = 0; i < defaultDownloadDocuments.length; i++) {
      if (defaultDownloadDocuments[i].enabledFor.includes(order.orderStatus)) {
        setValue(`downloadDocuments.${i}.value`, downloadAllDocuments);
      }
    }
  }, [
    downloadAllDocuments,
    defaultDownloadDocuments,
    setValue,
    order.orderStatus,
  ]);

  useEffect(() => {
    if (downloadedBlob && isProcessing) {
      if (isPrintView) {
        printFile(downloadedBlob);
      } else {
        // Download the file
        if (downloadedBlob && blobResponse) {
          downloadFile(downloadedBlob, blobResponse);
        }
      }
    }
  }, [
    downloadedBlob,
    isPrintView,
    isProcessing,
    blobResponse,
    order.orderIdentifier,
  ]);

  const isHighlighted = useCallback(
    (index: number) => {
      const document = printDocuments[index];
      const isStatusEnabled = document.enabledFor.includes(order.orderStatus);

      if (isPrintView) {
        return isStatusEnabled && document.value > 0;
      }

      if (isMobile) {
        return isStatusEnabled;
      }

      return downloadDocuments[index].value;
    },
    [
      printDocuments,
      downloadDocuments,
      order.orderStatus,
      isPrintView,
      isMobile,
    ]
  );
  const isFieldDisabled = useCallback(
    (field: { enabledFor: readonly OrderStatus[] }) => {
      return field.enabledFor.includes(order.orderStatus);
    },
    [order.orderStatus]
  );

  return (
    <Modal
      isOpen={true}
      onClose={onClose}
      ignoreViewport={isMobile}
      heading={
        <Stack direction="row" justifyContent="space-between" spacing={2}>
          <Stack direction="row" alignItems="center" spacing={2}>
            {!isMobile && (
              <ToggleButtonGroup
                onChange={(_, value) => {
                  if (value == null) return;
                  setIsPrintView(value);
                  setIsProcessing(false);
                }}
                exclusive
                value={isPrintView}
                fullWidth
                sx={{ width: 100, mb: 2 }}
              >
                <ToggleButton value={true} sx={{ width: 100 }}>
                  <PrintOutlined />
                </ToggleButton>
                <ToggleButton value={false} sx={{ width: 100 }}>
                  <FileDownloadOutlined />
                </ToggleButton>
              </ToggleButtonGroup>
            )}
            <Stack>
              <Typography variant="body1" fontWeight={600}>
                {isPrintView ? "Print" : "Download"} Documents
              </Typography>
              <Typography variant="body2" color="grey">
                ID {order.orderIdentifier}
              </Typography>
            </Stack>
          </Stack>

          {isPrintView ? (
            <Stack direction="row" alignItems="center" spacing={2} pr={2}>
              <Typography variant="body1">Print All</Typography>
              <TextField
                type="number"
                inputProps={{ min: 0, max: 9 }}
                size="small"
                {...register(`printAllDocuments`, {
                  onChange: (e) => {
                    const value = Number(e.target.value);
                    setValue("printAllDocuments", value);
                  },
                })}
              />
            </Stack>
          ) : !isMobile ? (
            <CheckboxField
              label="Select All"
              labelPlacement="start"
              checked={downloadAllDocuments}
              onChange={() => setDownloadAllDocuments(!downloadAllDocuments)}
              sx={{ mr: 2 }}
            />
          ) : null}
        </Stack>
      }
    >
      <Box component="form" sx={{ width: { xs: "100%", sm: "550px" } }}>
        {fields.map((field, index) => (
          <Card
            key={field.id}
            sx={{
              mb: 1,
              p: 0,
              backgroundColor:
                isMobile && !isFieldDisabled(field) ? "grey.100" : "",
            }}
          >
            <CardActionArea
              disableRipple={!isMobile}
              disabled={!field.enabledFor.includes(order.orderStatus)}
              onClick={() =>
                isMobile ? handleSingleDownload(field.field) : null
              }
            >
              <Stack
                spacing={2}
                p={isMobile ? 1 : 2}
                direction="row"
                justifyContent="space-between"
                alignItems="center"
              >
                <Stack direction="row" alignItems="center" spacing={2}>
                  <Avatar
                    sx={{
                      bgcolor: isHighlighted(index)
                        ? "primary.light"
                        : "gray100",
                      color: isHighlighted(index) ? "primary.main" : "gray100",
                    }}
                  >
                    <DescriptionOutlined />
                  </Avatar>
                  <Typography variant="body1">{field.label}</Typography>
                </Stack>
                {isPrintView ? (
                  <TextField
                    disabled={!isFieldDisabled(field)}
                    type="number"
                    inputProps={{ min: 0, max: 9 }}
                    size="small"
                    {...register(`printDocuments.${index}.value`, {
                      onChange: (e) => {
                        setValue("printAllDocuments", 0);
                      },
                    })}
                  />
                ) : !isMobile ? (
                  <CheckboxField
                    label=""
                    disabled={!isFieldDisabled(field)}
                    {...register(`downloadDocuments.${index}.value`)}
                    checked={downloadDocuments[index].value}
                  />
                ) : null}
              </Stack>
            </CardActionArea>
          </Card>
        ))}
        <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
          <Button
            variant="outlined"
            onClick={onClose}
            sx={{
              marginRight: isMobile ? 0 : 2,
              marginTop: isMobile ? 2 : "inherit",
            }}
          >
            {isMobile ? "Close" : "Cancel"}
          </Button>
          {!isMobile && (
            <Button
              onClick={handlePrint}
              variant="contained"
              type="submit"
              color="primary"
              startIcon={
                isPrintView ? <PrintOutlined /> : <FileDownloadOutlined />
              }
              disabled={
                !isValid || blobLoading || tokenLoading || !hasPendingItems
              }
            >
              {isPrintView ? "Print" : "Download"}
            </Button>
          )}
        </Box>
      </Box>
    </Modal>
  );
};
