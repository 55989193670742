import {
  Button,
  Divider,
  SelectChangeEvent,
  Stack,
  Typography,
} from "@mui/material";
import { DateTime } from "luxon";
import { useEffect, useMemo, useState } from "react";
import { GroupedOption } from "../../components/AutocompleteField";
import { DatePicker } from "../../components/DatePicker";
import MultiSelectField, { Option } from "../../components/MultiSelectField";
import { getModelConstructionTypeDisplayName } from "../../types/model";
import {
  getOrderQuotePhaseDisplayName,
  getOrderStatusDisplayName,
  getSoldAsTypeDisplayName,
} from "../../types/order";
import {
  getReportQueryOptionsTypeDisplayName,
  Report,
  ReportQueryOptions,
  ReportSelectedQueryOptions,
  reportSelectedQueryOptionsDateFormat,
} from "../../types/report";
import { stateSelectOptions } from "../../types/states";
import { useSession } from "../../hooks/useSession";
import { useApiRequest } from "../../hooks/useApiRequest";
import { Series } from "../../types/series";

interface ReportingRightSidebarTabReportFiltersProps {
  report: Report;
  queryOptions: ReportQueryOptions;
  onReportSelectedQueryOptionsChange: (
    selectedQueryOptions: ReportSelectedQueryOptions
  ) => void;
  onClearAllClick: () => void;
}

export const ReportingRightSidebarTabReportFilters: React.FC<
  ReportingRightSidebarTabReportFiltersProps
> = ({
  report,
  queryOptions,
  onReportSelectedQueryOptionsChange,
  onClearAllClick,
}) => {
  const { user } = useSession();
  const [selectedQueryOptions, setSelectedQueryOptions] =
    useState<ReportSelectedQueryOptions>(report.selectedQueryOptions);

  useEffect(() => {
    setSelectedQueryOptions(report.selectedQueryOptions);
  }, [report.selectedQueryOptions]);

  const tenantOptions = useMemo(
    () =>
      (user?.isRetailUser ? queryOptions.manufacturers : queryOptions.retailers).map((tenant) => ({
        value: tenant.tenantGuid,
        label: tenant.tenantName,
      })),
    [queryOptions.retailers,queryOptions.manufacturers,user?.isRetailUser]
  );

  const [seriesOptions, setSeriesOptions] = useState<{value:string,label:string}[]>([]);
  useMemo(
    () =>
      setSeriesOptions(queryOptions.series.map((x) => ({
        value: x.seriesGuid,
        label: x.seriesName,
      }))),
    [queryOptions.series,setSeriesOptions]
  );

  const { request: seriesRequest } = useApiRequest<Series[]>(false);
  const [usableModelOptions,setUsableModelOptions] = useState<{label:string,value:string,groupValue:string,groupLabel:string}[]>([]);
  useMemo(() => {
    if (!user?.isRetailUser) return;
    setSeriesOptions([]);
    setUsableModelOptions([]);
    selectedQueryOptions.manufacturers.forEach((mfgGuid) => {
      const mfg = queryOptions.manufacturers.find((m) => m.tenantGuid === mfgGuid);
      if (mfg) {
        seriesRequest(`/retailer/${mfg.tenantGuid}/series`, 
          { method: "GET" },
          { 
            onSuccess(data) {
              setSeriesOptions((oldOptions) => [...oldOptions, ...data.map((x) => ({
                value: x.seriesGuid,
                label: x.seriesName,
              }))]);
              const newModelOptions: {label:string,value:string,groupValue:string,groupLabel:string}[] = [];
              data.forEach((x) => {
                x.models.forEach((m) => {
                  newModelOptions.push(
                    {
                      label: m.modelNumber,
                      value: m.modelGuid,
                      groupValue: x.seriesGuid,
                      groupLabel: x.seriesName,
                    }
                  );
                });
              });
              setUsableModelOptions((oldOptions) => [...oldOptions, ...newModelOptions]);
            }
          }
        );
      }
    });
  },[user?.isRetailUser,selectedQueryOptions.manufacturers,setSeriesOptions,queryOptions.manufacturers,seriesRequest,setUsableModelOptions]);

  const modelOptions = useMemo(() => {
    if (user?.isRetailUser) {
      return Array<GroupedOption<string> & {}>();
    }

    let models = queryOptions.models ?? [];

    if (selectedQueryOptions.series.length > 0) {
      const selectedSeries = queryOptions.series.filter((s) =>
        selectedQueryOptions.series.includes(s.seriesGuid)
      );
      // Only display models for the selected series.
      models = models.filter((m) =>
        selectedSeries.some((s) => s.seriesGuid === m.seriesGuid)
      );
    }

    const options: Array<GroupedOption<string> & {}> = models.map((model) => {
      const series = queryOptions.series.find(
        (s) => s.seriesGuid === model.seriesGuid
      );
      return {
        label: model.modelNumber,
        value: model.modelGuid,
        groupValue: series?.seriesGuid ?? "Unknown",
        groupLabel: series?.seriesName ?? "Unknown",
      };
    });

    return options;
  }, [queryOptions.models, queryOptions.series, selectedQueryOptions.series, user?.isRetailUser]);

  const soldAsOptions = useMemo(
    () =>
      queryOptions.soldas.map((x) => ({
        value: x,
        label: getSoldAsTypeDisplayName(x),
      })),
    [queryOptions.soldas]
  );

  const statusOptions = useMemo(
    () =>
      queryOptions.status.map((x) => ({
        value: x,
        label: getOrderStatusDisplayName(x),
      })),
    [queryOptions.status]
  );

  const quotePhaseOptions = useMemo(
    () =>
      queryOptions.phases.map((x) => ({
        value: x,
        label: getOrderQuotePhaseDisplayName(x),
      })),
    [queryOptions.phases]
  );

  const homeTypeOptions = useMemo(
    () =>
      queryOptions.types.map((x) => ({
        value: x,
        label: getReportQueryOptionsTypeDisplayName(x),
      })),
    [queryOptions.types]
  );

  const constructionTypeOptions = useMemo(
    () =>
      queryOptions.constructionTypes.map((x) => ({
        value: x,
        label: getModelConstructionTypeDisplayName(x),
      })),
    [queryOptions.constructionTypes]
  );

  const toValue = useMemo(
    () =>
      DateTime.fromFormat(
        selectedQueryOptions.toDate,
        reportSelectedQueryOptionsDateFormat
      ),
    [selectedQueryOptions.toDate]
  );

  const fromValue = useMemo(
    () =>
      DateTime.fromFormat(
        selectedQueryOptions.fromDate,
        reportSelectedQueryOptionsDateFormat
      ),
    [selectedQueryOptions.fromDate]
  );

  const fromDateError = useMemo(() => {
    return toValue < fromValue ? "Date must be before To Date" : undefined;
  }, [fromValue, toValue]);

  const toDateError = useMemo(() => {
    return fromValue > toValue ? "Date must be after From Date" : undefined;
  }, [fromValue, toValue]);

  const retailerSalesRepSelectOptions = useMemo(
    () =>
      queryOptions.retailerSalesReps.map((x) => ({
        value: x,
        label: x,
      })),
    [queryOptions.retailerSalesReps]
  );

  const handleChange =
    (key: keyof ReportSelectedQueryOptions) =>
    (event: SelectChangeEvent<unknown>) => {
      const value = event.target.value;
      setSelectedQueryOptions((preSelectedQueryOptions) => {
        return getUpdateReportSelectedQueryOptions(
          preSelectedQueryOptions,
          key,
          value as ReportSelectedQueryOptions[typeof key]
        );
      });
    };

  const handleDateChange =
    (key: keyof ReportSelectedQueryOptions) => (dateTime: DateTime | null) => {
      if (dateTime == null) {
        return;
      }
      const value = dateTime?.toFormat(reportSelectedQueryOptionsDateFormat);
      setSelectedQueryOptions((preSelectedQueryOptions) => {
        return getUpdateReportSelectedQueryOptions(
          preSelectedQueryOptions,
          key,
          value
        );
      });
    };

  const handleDelete =
    (key: keyof ReportSelectedQueryOptions) =>
    (option: Option<ReportSelectedQueryOptions[typeof key][number]>) => {
      if (key === "toDate" || key === "fromDate") {
        return;
      }
      setSelectedQueryOptions((preSelectedQueryOptions) => {
        const selectedValues = preSelectedQueryOptions[key];
        const newSelectedValues = selectedValues.filter(
          (v) => v !== option.value
        );
        return getUpdateReportSelectedQueryOptions(
          preSelectedQueryOptions,
          key,
          newSelectedValues
        );
      });
    };

  const getUpdateReportSelectedQueryOptions = <
    K extends keyof ReportSelectedQueryOptions
  >(
    preSelectedQueryOptions: ReportSelectedQueryOptions,
    key: K,
    value: ReportSelectedQueryOptions[K]
  ) => {
    let updatedSelectedQueryOptions = {
      ...preSelectedQueryOptions,
      [key]: value,
    };

    if (key === "series" && updatedSelectedQueryOptions.series.length > 0) {
      // De-select any models that do not belong to this series.
      const selectedSeries = queryOptions.series.filter((series) => {
        return updatedSelectedQueryOptions.series.includes(series.seriesGuid);
      });
      const selectedModels = updatedSelectedQueryOptions.models.filter(
        (selectedModelGuid) => {
          const model = queryOptions.models.find(
            (model) => model.modelGuid === selectedModelGuid
          );
          return (
            model &&
            selectedSeries.some((series) => series.seriesGuid === model.seriesGuid)
          );
        }
      );
      updatedSelectedQueryOptions.models = selectedModels;
    }

    if (key === "status" && updatedSelectedQueryOptions.status.length > 0) {
      const statusWasAdded =
        preSelectedQueryOptions.status.length <=
        updatedSelectedQueryOptions.status.length;

      if (statusWasAdded) {
        const addedStatus = updatedSelectedQueryOptions.status.find(
          (s) => !preSelectedQueryOptions.status.includes(s)
        );

        // If the user selected Quote, then remove any other statuses.
        if (addedStatus === "q") {
          updatedSelectedQueryOptions.status = ["q"];
        } else if (addedStatus === "p") {
          updatedSelectedQueryOptions.status = ["p"];
        }
        // Otherwise, make sure Quote is not selected and quote phases are cleared.
        else {
          updatedSelectedQueryOptions.status =
            updatedSelectedQueryOptions.status.filter((s) => s !== "q");

          updatedSelectedQueryOptions.phases = [];
        }
      }
    }

    return updatedSelectedQueryOptions;
  };

  const handleApplyClick = () => {
    onReportSelectedQueryOptionsChange(selectedQueryOptions);
  };

  return (
    <>
      <Stack spacing={2} sx={{ flexGrow: 1, overflowY: "auto" }} padding={2}>
        <Stack direction="row" spacing={1}>
          <DatePicker
            label="From"
            required
            fullWidth
            allowNoDate={false}
            value={fromValue}
            onChange={handleDateChange("fromDate")}
            helperText={fromDateError}
            error={fromDateError != null}
          />
          <DatePicker
            label="To"
            required
            fullWidth
            allowNoDate={false}
            value={toValue}
            onChange={handleDateChange("toDate")}
            helperText={toDateError}
            error={toDateError != null}
          />
        </Stack>
        <MultiSelectField
          label={user?.isRetailUser ? "Manufacturer" : "Retailer"}
          placeholder={user?.isRetailUser ? "Select Manufacturer" : "Select Retailer"}
          fullWidth
          multiple
          options={tenantOptions}
          value={user?.isRetailUser ? selectedQueryOptions.manufacturers : selectedQueryOptions.retailers}
          onChange={handleChange(user?.isRetailUser ? "manufacturers" : "retailers")}
          onDelete={handleDelete(user?.isRetailUser ? "manufacturers" : "retailers")}
        />
        {!user?.isRetailUser && (
          <MultiSelectField
            label="Series"
            placeholder={user?.isRetailUser && selectedQueryOptions.manufacturers.length === 0 ? "Select Manufacturer" : "Select Series"}
            fullWidth
            multiple
            options={seriesOptions}
            value={selectedQueryOptions.series}
            onChange={handleChange("series")}
            onDelete={handleDelete("series")}
          />
        )} 
        <MultiSelectField
          label="Model"
          placeholder={user?.isRetailUser && selectedQueryOptions.manufacturers.length === 0 ? "Select Manufacturer" : "Select Model"}
          fullWidth
          multiple
          options={user?.isRetailUser ? usableModelOptions : modelOptions}
          value={selectedQueryOptions.models}
          onChange={handleChange("models")}
          onDelete={handleDelete("models")}
        />
        <MultiSelectField
          label="Sold As"
          placeholder="Select Sold As"
          fullWidth
          multiple
          options={soldAsOptions}
          value={selectedQueryOptions.soldas}
          onChange={handleChange("soldas")}
          onDelete={handleDelete("soldas")}
        />
        <MultiSelectField
          label="Status"
          placeholder="Select Status"
          fullWidth
          multiple
          options={statusOptions}
          value={selectedQueryOptions.status}
          onChange={handleChange("status")}
          onDelete={handleDelete("status")}
        />
        <MultiSelectField
          label="Quote Phase"
          placeholder="Select Quote Phase"
          fullWidth
          multiple
          options={quotePhaseOptions}
          value={selectedQueryOptions.phases}
          onChange={handleChange("phases")}
          onDelete={handleDelete("phases")}
        />
        <MultiSelectField
          label="Home Type"
          placeholder="Select Home Type"
          fullWidth
          multiple
          options={homeTypeOptions}
          value={selectedQueryOptions.types}
          onChange={handleChange("types")}
          onDelete={handleDelete("types")}
        />
        <MultiSelectField
          label="Construction Type"
          placeholder="Select Construction Type"
          fullWidth
          multiple
          options={constructionTypeOptions}
          value={selectedQueryOptions.constructionTypes}
          onChange={handleChange("constructionTypes")}
          onDelete={handleDelete("constructionTypes")}
        />
        <MultiSelectField
          label="Retailer Sales Rep"
          placeholder="Select Retailer Sales Rep"
          fullWidth
          multiple
          options={retailerSalesRepSelectOptions}
          value={selectedQueryOptions.retailerSalesReps}
          onChange={handleChange("retailerSalesReps")}
          onDelete={handleDelete("retailerSalesReps")}
        />
        <MultiSelectField
          label="State"
          placeholder="Select State"
          fullWidth
          multiple
          options={stateSelectOptions}
          value={selectedQueryOptions.states}
          onChange={handleChange("states")}
          onDelete={handleDelete("states")}
        />
        <Stack justifyContent="center" alignItems="center">
          <Typography
            color="grey.500"
            fontWeight={500}
            fontSize="14px"
            marginTop={2}
          >
            Need a different report?
          </Typography>
          <Button
            href={`mailto:${process.env.REACT_APP_CONTACT_SUPPORT_EMAIL}?subject=Custom Report Request`}
            sx={{ fontSize: "14px", paddingY: 0.5, lineHeight: "normal" }}
          >
            Contact Support
          </Button>
        </Stack>
      </Stack>

      <Divider />

      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        paddingX={3}
        paddingY={2}
      >
        <Button onClick={onClearAllClick}>Clear All</Button>
        <Button variant="contained" onClick={handleApplyClick}>
          Apply
        </Button>
      </Stack>
    </>
  );
};
