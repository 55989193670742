import React, { useEffect, useMemo } from "react";
import { Alert, Box, Button, Grid, Stack } from "@mui/material";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { Modal } from "../../components/Modal";
import InputField from "../../components/InputField";
import { useApiRequest } from "../../hooks/useApiRequest";
import { Category } from "../../types/category";
import { Group } from "../../types/group";
import { Subgroup } from "../../types/subgroup";
import { Series } from "../../types/series";
import { DatePicker } from "../../components/DatePicker";
import { DateTime } from "luxon";
import MultiSelectField from "../../components/MultiSelectField";
import CheckboxField from "../../components/CheckboxField";

type FormData = {
  subgroupName: string;
  startDate: DateTime | null;
  endDate: DateTime | null | undefined;
  subgroupIsRequired: boolean;
  customOptionAllowed: boolean;
  series?: string[];
};

type NewEditGroupModalProps = {
  isOpen: boolean;
  onSaveSuccessful: () => void;
  onClose: () => void;
  category?: Category;
  group?: Group;
  subgroup?: Subgroup;
  isNew: boolean;
};

const getDefaultValues = (subGroup?: Subgroup): FormData => {
  return {
    subgroupName: subGroup?.subgroupName ?? "",
    startDate: subGroup?.startDate
      ? DateTime.fromSeconds(subGroup?.startDate)
      : null,
    endDate: subGroup?.endDate ? DateTime.fromSeconds(subGroup?.endDate) : null,
    subgroupIsRequired: subGroup?.subgroupIsRequired ?? false,
    customOptionAllowed: subGroup?.customOptionAllowed ?? false,
    series: subGroup?.series?.map((s) => s.seriesGuid) ?? [],
  };
};

export const NewEditSubGroupModal: React.FC<NewEditGroupModalProps> = ({
  isOpen,
  onSaveSuccessful,
  onClose,
  category,
  group,
  subgroup,
  isNew,
}) => {
  const {
    data: saveGroupData,
    loading: saveGroupLoading,
    request: saveGroupRequest,
    status: saveGroupStatus,
    error: saveGroupError,
  } = useApiRequest<Group>(true);
  const {
    data: series,
    request: seriesRequest,
    loading: seriesLoading,
  } = useApiRequest<Series[]>(false);

  useEffect(() => {
    seriesRequest(`/company/series`, { method: "GET" });
  }, [seriesRequest]);

  const seriesOptions = useMemo(() => {
    if (!series) return [];
    return series
      ?.reduce((acc: Series[], s) => {
        // Allow for a deactivated series to show up in the list so that the user
        // may remove it. But don't add it to the list otherwise
        if (subgroup?.series.map((s) => s.seriesGuid).includes(s.seriesGuid)) {
          acc.push(s);
        } else if (s.isActive) acc.push(s);
        return acc;
      }, [])
      ?.map((s) => ({
        label: s.seriesName,
        value: s.seriesGuid,
      }));
  }, [series, subgroup?.series]);

  const {
    control,
    register,
    handleSubmit,
    formState,
    watch,
    trigger,
    setValue,
    formState: { errors },
  } = useForm<FormData>({
    defaultValues: getDefaultValues(subgroup),
    reValidateMode: "onChange",
    mode: "onChange",
  });

  const startDate = watch("startDate");
  const endDate = watch("endDate");
  const selectedSeries = watch("series");

  useEffect(() => {
    trigger("startDate");
    trigger("endDate");
  }, [startDate, endDate, trigger]);

  const onSubmit: SubmitHandler<FormData> = (data: FormData) => {
    if (isNew) {
      saveGroupRequest(
        `/company/options/groups/${group?.groupGuid}/subgroups/add`,
        {
          method: "POST",
          data,
        }
      );
    } else {
      saveGroupRequest(
        `/company/options/subgroups/${subgroup?.subgroupGuid}/edit`,
        {
          method: "POST",
          data,
        }
      );
    }
  };

  useEffect(() => {
    if (
      saveGroupData &&
      saveGroupStatus === "ok" &&
      saveGroupLoading === false
    ) {
      onSaveSuccessful();
    }
  }, [saveGroupData, onSaveSuccessful, saveGroupStatus, saveGroupLoading]);

  return (
    <Modal
      heading={isNew ? "New Sub Group" : "Edit Sub Group"}
      isOpen={isOpen}
      onClose={onClose}
    >
      <Box sx={{ width: { xs: "100%", sm: "371px" } }}>
        {saveGroupError && (
          <Alert severity="error" sx={{ marginBottom: 2 }}>
            {saveGroupError.response?.status === 409
              ? "Subgroup already exists with this name and dates."
              : "Something went wrong. Please try again."}
          </Alert>
        )}
        <Stack component="form" onSubmit={handleSubmit(onSubmit)} spacing={3}>
          <Grid container marginBottom={2}>
            <Grid item xs={12} sm={12}>
              <Grid item xs={12} sm={12} marginBottom={2}>
                <InputField
                  label="Sub Group Name"
                  placeholder="Enter name"
                  fullWidth
                  required
                  {...register("subgroupName", { required: "Enter name" })}
                  error={!!formState.errors.subgroupName}
                  helperText={formState.errors.subgroupName?.message}
                />
              </Grid>
              <Grid container spacing={2} marginBottom={2}>
                <Grid item xs={12} sm={6}>
                  <Controller
                    name="startDate"
                    control={control}
                    rules={{
                      required: "Enter Start Date",
                      validate: (startDate) => {
                        if (!startDate) {
                          return "Start Date is required";
                        }
                        if (startDate && !endDate) {
                          return true;
                        }
                        if (endDate && startDate > endDate) {
                          return "Start Date must be before End Date";
                        }
                        return true;
                      },
                    }}
                    render={({ field }) => (
                      <DatePicker
                        label="Start Date"
                        fullWidth
                        {...field}
                        required
                        error={!!errors.startDate}
                        helperText={errors.startDate?.message}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Controller
                    name="endDate"
                    control={control}
                    rules={{
                      validate: (endDateValue) => {
                        if (
                          endDateValue &&
                          startDate &&
                          startDate > endDateValue
                        ) {
                          return "End Date must be after Start Date";
                        }
                        return true;
                      },
                    }}
                    render={({ field }) => (
                      <DatePicker
                        label="End Date"
                        required
                        fullWidth
                        allowNoDate
                        {...field}
                        error={!!errors.endDate}
                        helperText={errors.endDate?.message}
                      />
                    )}
                  />
                </Grid>
              </Grid>
              <Grid item xs={12} sm={12} marginBottom={2}>
                <Controller
                  control={control}
                  name="series"
                  render={({ field, fieldState }) => (
                    <MultiSelectField
                      {...field}
                      disabled={seriesLoading || !series}
                      label="Series"
                      required
                      fullWidth
                      options={seriesOptions}
                      placeholder="Select Series"
                      error={!!fieldState.error}
                      helperText={fieldState.error?.message}
                      onDelete={(option) => {
                        const newSeries = selectedSeries!.filter((s) => {
                          return s !== option.value;
                        });
                        setValue("series", newSeries);
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <Controller
                  name="subgroupIsRequired"
                  control={control}
                  render={({ field }) => (
                    <CheckboxField
                      {...field}
                      label="Sub Group is Required"
                      checked={field.value}
                    />
                  )}
                />
              </Grid>
              {category?.categoryName !== 'Factory Trim Out' && (
                <Grid item xs={12} sm={12}>
                  <Controller
                    name="customOptionAllowed"
                    control={control}
                    render={({ field }) => (
                      <CheckboxField
                        {...field}
                        label="Allow Custom Option"
                        checked={field.value}
                      />
                    )}
                  />
                </Grid>
              )}
            </Grid>
          </Grid>
          <Stack direction="row" justifyContent="flex-end">
            <Button
              variant="outlined"
              onClick={onClose}
              sx={{ marginRight: 2 }}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              type="submit"
              color="primary"
              disabled={saveGroupLoading}
            >
              Save
            </Button>
          </Stack>
        </Stack>
      </Box>
    </Modal>
  );
};
