import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Unstable_Grid2"; // Grid version 2
import { useFieldArray, useForm } from "react-hook-form";
import {
  isOrderCardFinancialFeeReadOnly,
  type OrderCardFinancialFees as OrderCardFinancialFeesType,
} from "../../types/orderCardFinancialFees";
import { getOrderCardError } from "../../types/orderCard";
import { OrderCardBase } from "./OrderCardBase";
import { AddFeeModal } from "./AddFeeModal";
import { useOrderCard } from "./OrderCardContext";
import InputField from "../../components/InputField";
import { OrderCardActionButton } from "./OrderCardActionButton";
import { Order } from "../../types/order";
import { InputAdornment } from "@mui/material";
import { useApiRequest } from "../../hooks/useApiRequest";
import { OrderCardEditResponse } from "./orderCardEditResponse";
import { OrderCard } from "../../types/orderCard";

type FormData = {
  fees: OrderCardFinancialFeesType["cardData"]["fees"];
};

const getDefaultValues = (orderCard: OrderCardFinancialFeesType): FormData => {
  return {
    fees: orderCard?.cardData.fees ?? [],
  };
};

interface OrderCardFinancialFeesProps {
  isTemplate: Order["isTemplate"];
  orderCard: OrderCardFinancialFeesType;
  expanded: boolean;
  groupIndex: number;
  order: Order;
  onOrderCardEdited: (
    orderCardEditResponse: OrderCardEditResponse<OrderCard>
  ) => void;
}

export const OrderCardFinancialFees: React.FC<OrderCardFinancialFeesProps> = ({
  isTemplate,
  orderCard,
  expanded,
  groupIndex,
  order,
  onOrderCardEdited,
}) => {
  const [isFeeModalOpen, setIsFeeModalOpen] = useState(false);
  const { saveOrderCard, setEditingOrderCard } = useOrderCard();

  const disabled = orderCard.isLocked;
  
  const { control, register, reset, formState, getValues, watch, setError, } =
    useForm<FormData>({
      defaultValues: getDefaultValues(orderCard),
      reValidateMode: "onChange",
      mode: "onChange",
    });
  const { fields } = useFieldArray({ control, name: "fees" });
  const fees = watch("fees");

  const {
    request: saveOrderCardRequest,
  } = useApiRequest<OrderCardEditResponse<OrderCardFinancialFeesType>>(false, "orderCard");

  const handleBlurField = () => {
    const cardData = getValues();
    saveOrderCardRequest(`/ordercards/${orderCard.orderCardGuid}/edit`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      data: { 'cardData': cardData },
    }, {
      onSuccess: (data) => {
        onOrderCardEdited(data);
      },
      onUnsuccess() {
        //
      } 
    });
  };

  const handleEditingField = () => {
    setEditingOrderCard(orderCard);
  };

  const handleClearClick = () => {
    const clearedOrderCard: OrderCardFinancialFeesType = {
      ...orderCard,
      cardData: {
        ...orderCard.cardData,
        fees: orderCard.cardData.fees.map((fee) => {
          if (isOrderCardFinancialFeeReadOnly(fee.feeName)) {
            return fee;
          }
          return { ...fee, feeAmount: 0 };
        }),
      },
    };
    const defaultValues = getDefaultValues(clearedOrderCard);
    reset(defaultValues);
    saveOrderCard(orderCard.orderCardGuid, defaultValues);
  };

  const handleAddFeeClick = () => {
    setIsFeeModalOpen(true);
  };

  const handleCloseAddFeeModal = () => {
    setIsFeeModalOpen(false);
  };

  const handleAddFeeSaveSuccessful = (
    orderCardFee: OrderCardFinancialFeesType
  ) => {
    const defaultValues = getDefaultValues(orderCardFee);
    reset(defaultValues);
    setIsFeeModalOpen(false);
  };

  useEffect(() => {
    reset(getDefaultValues(orderCard), { keepDirtyValues: orderCard.cardData.updatedAsRelatedCard !== true });
  }, [orderCard, reset]);

  useEffect(() => {
    if (orderCard.errors.length === 0) return;

    const freightFeeError = getOrderCardError(orderCard, "efef");

    if (!!freightFeeError) {
      setError("fees.0.feeName", {
        type: "manual",
        message: "Freight cannot be $0",
      });
    }

    const salesAllowanceFeeError = getOrderCardError(orderCard, "efesa");
    if (!!salesAllowanceFeeError) {
      for(let index=0; index<orderCard.cardData.fees.length; index++) {
        if (orderCard.cardData.fees[index].feeName === 'Sales Allowance') {
          setError(`fees.${index}.feeName`, {
            type: "manual",
            message: "Sales Allowance must be negative or zero",
          });
          break;
        }
      }
    }
  }, [disabled, orderCard, setError]);

  return (
    <>
      <OrderCardBase
        action={
          !disabled &&
          !isTemplate && (
            <OrderCardActionButton onClick={handleAddFeeClick}>
              Add Fee
            </OrderCardActionButton>
          )
        }
        disabled={disabled}
        orderCard={orderCard}
        onClearClick={handleClearClick}
        expanded={expanded}
        groupIndex={groupIndex}
      >
        <Grid container spacing={2}>
          {fields.map((field, index) => {
            const fieldErrors = formState.errors.fees?.[index];
            const fee = fees[index];
            return (
              <Grid key={fee.feeName} xs={12} sm={12} md={6}>
                <InputField
                  {...register(`fees.${index}.feeAmount`, {
                    valueAsNumber: true,
                    onBlur: handleBlurField,
                  })}
                  startAdornment={
                    <InputAdornment position="start">$</InputAdornment>
                  }
                  type="number"
                  intOnly={true}
                  label={`${fee.feeName}`}
                  onFocus={handleEditingField}
                  placeholder={(disabled || isOrderCardFinancialFeeReadOnly(fee.feeName)) ? '' : `Enter ${fee.feeName}`}
                  fullWidth
                  disabled={
                    disabled || isOrderCardFinancialFeeReadOnly(fee.feeName)
                  }
                  sx={{ marginBottom: 2 }}
                  error={!!fieldErrors?.feeName}
                  helperText={fieldErrors?.feeName?.message}
                />
              </Grid>
            );
          })}
        </Grid>
      </OrderCardBase>
      {isFeeModalOpen && (
        <AddFeeModal
          isOpen={isFeeModalOpen}
          orderCardGuid={orderCard.orderCardGuid}
          onSaveSuccessful={handleAddFeeSaveSuccessful}
          onClose={handleCloseAddFeeModal}
        />
      )}
    </>
  );
};
