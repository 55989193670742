import { Grid, Typography, Box, Container, Paper } from "@mui/material";
import { usePageMetadata } from "../../hooks/usePageMetadata";
import { routes } from "../../routes";

const TermsRoute: React.FC = () => {
  usePageMetadata({ title: routes.terms.label });

  return (
    <>
      <Grid
        container
        style={{
          background: "url(/login-background.jpg) no-repeat center center",
          backgroundSize: "cover",
          backgroundColor: 'rgba(255, 255, 255, 0.6)',
          backgroundBlendMode: 'overlay',
          minHeight: "100vh"
        }}
      >
        <Grid item xs={12} sm={12} md={10} lg={8} style={{ margin: "auto", paddingTop: "40px", paddingBottom: "60px" }}>
          <Paper elevation={3} sx={{ padding: 4, mb: 4 }}>
            <Container maxWidth="md">
              <Box sx={{ mb: 4, textAlign: "center" }}>
                <Typography variant="h4" gutterBottom fontWeight="bold" sx={{ textDecoration: "underline" }}>
                  TERMS OF USE
                </Typography>
              </Box>

              <Box sx={{ textAlign: "justify", textIndent: "0.5in", mb: 3 }}>
                <Typography variant="body1">
                  Thank you for using Red Roots Technologies LLC's software and platform, including its
                  Atlas Ally product (collectively, the "Software"). These terms and conditions
                  apply to your access to and/or your use of the Software and any information,
                  products, platform or other things made available through or in relation to the
                  Software. <strong>By using the Software, or submitting any communications or content
                  to us, you accept and agree to all of these terms and conditions set forth
                  below (the "Conditions"), which form a legally binding agreement between you
                  and Red Roots Technologies LLC.</strong> ("Red Roots"). (Red Roots and you, the Customer
                  and anyone authorized or permitted to access or use the Software by the
                  Customer (collectively, "You" or the "Customer"), may be referred to
                  individually as a "Party" and collectively as the "Parties"). These Terms of
                  Use create an Agreement between the Parties that is to be read in conjunction
                  with any other Agreement or Agreements between them. To the extent of any
                  conflict between this and any other Agreement between the Parties, the
                  Agreement shall govern. By accessing the Software, Customer agrees to these
                  Terms of Use and that they form a binding Agreement between Customer and Red
                  Roots thereby binding Customer and anyone Customer provides access to the Software.
                  By clicking "Agree" and by accessing the Software Customer agrees to be bound
                  by these Conditions.
                </Typography>
              </Box>

              <Box sx={{ mb: 3 }}>
                <Typography variant="h6" gutterBottom fontWeight="bold">
                  1. Agreement Between Customer and Red Roots.
                </Typography>
                <Box sx={{ ml: 2, textAlign: "justify" }}>
                  <Typography variant="body1">
                    This Agreement, and the Terms of Use set forth herein,
                    govern Customer's use of Red Roots's Software. By using the Software, You
                    explicitly accepts these Terms of Use. We strongly recommend that, as You read
                    these Terms of Use, You also accesses and reads the information contained in
                    the other pages and websites referred to in these Terms of Use, as they may
                    contain further terms and conditions that apply to the Customer. Underlined
                    words and phrases are links to these pages and websites. The term "Agreement"
                    means the agreement formed between the Customer and Red Roots pursuant to these
                    Terms of Use and any other terms and conditions posted on the Red Roots's
                    Website (the "Website"), and any other written agreement between Customer and
                    us, as the same may be modified from time to time, all of which are
                    incorporated herein by reference. To use the Software Customer needs
                    compatible hardware, Software and Internet access. Red Roots's Software's
                    performance may be affected by these factors.
                  </Typography>
                </Box>
              </Box>

              <Box sx={{ mb: 3 }}>
                <Typography variant="h6" gutterBottom fontWeight="bold">
                  2. Term and Termination.
                </Typography>
                <Box sx={{ ml: 2 }}>
                  <Typography variant="body1" gutterBottom>
                    (a) Term of this Agreement shall be for a period equal to the
                    existence of Customer's Subscription per its Subscription Agreement.
                  </Typography>
                  <Box sx={{ ml: 3 }}>
                    <Typography variant="body2" gutterBottom>
                      (i) Payment of the Fees shall be due for any Subscription at the
                      beginning of each monthly or annual subscription Term;
                    </Typography>
                    <Typography variant="body2" gutterBottom>
                      (ii) Red Roots may change, modify, create, or cancel the terms and
                      conditions of any Subscription Package(s) at any time and in its sole
                      discretion. Customer, however, shall have access to its then existing
                      Subscription Package to the end of its current, paid Subscription Term.
                    </Typography>
                  </Box>
                  <Typography variant="body1" gutterBottom>
                    (b) Termination for Convenience: Customer may terminate its
                    Agreement with Red Roots at any time during a subscription Term at any time and
                    for any reason pursuant to the terms and conditions of the Subscription
                    Agreement. Termination shall be effective as of the conclusion of the paid-for
                    subscription period.
                  </Typography>
                  <Typography variant="body1">
                    (c) Effect of Termination. Upon termination of the Agreement, the Customer
                    shall cease all use of, and all access to, the Software Services as of the
                    conclusion of any paid-for subscription period.
                  </Typography>
                </Box>
              </Box>

              <Box sx={{ mb: 3 }}>
                <Typography variant="h6" gutterBottom fontWeight="bold">
                  3. Software and Software License.
                </Typography>
                <Box sx={{ ml: 2 }}>
                  <Typography variant="body1" gutterBottom>
                    (a) Subject to the terms of any Subscription Agreement between the
                    Parties, during the Term, Red Roots hereby grants and Customer accepts a
                    limited, non-exclusive, non-delegable, non-sublicensable, and non-transferable
                    right and license to access and use the Red Roots software made a part of the Software
                    solely for Customer's own business purposes and solely in accordance with the
                    terms and conditions of the Subscription Agreement and these Terms of Use. Customer
                    acknowledges and agrees that ownership of the Software, including any modifications
                    thereto and any related documentation that may be provided to (or developed by)
                    Customer from time to time, is the sole confidential property of Red Roots and Customer
                    shall not gain any right, title, or interest therein except for the limited
                    license granted to Customer in accordance with this Section.
                  </Typography>
                  <Typography variant="body1">
                    (b) Customer agrees not to Reverse Engineer the Software or engage
                    others to attempt to do so, or to sell, resell or provide any services to any
                    end-user who attempts to reverse engineer, or engages others to attempt to
                    reverse engineer, the Software. As used herein, "reverse engineer" shall mean
                    to disassemble, decompile, and/or analyze the Software for the purpose of
                    recreating its workings, or for the purpose of revealing the embedded software
                    source code or data reporting protocols. In the event of any inconsistency
                    between this provision and any Software Licensing Agreement, the Software
                    Licensing Agreement shall govern.
                  </Typography>
                </Box>
              </Box>
              
              <Box sx={{ mb: 3 }}>
                <Typography variant="h6" gutterBottom fontWeight="bold">
                  4. Fees and Payments.
                </Typography>
                <Box sx={{ ml: 2 }}>
                  <Typography variant="body1" gutterBottom>
                    (a) The Customer's fees shall be set forth in the Subscription Agreement.
                  </Typography>
                  <Typography variant="body1" gutterBottom>
                    (b) Suspension of Services. In the event Customer does not timely
                    make any payment or payments due pursuant to these Terms or any other agreement
                    between the Parties, Red Roots may, in its sole discretion and in addition to
                    any other remedies provided by this Agreement or any applicable law, suspend Customer's
                    access to Software Services until such time as the payment is made in full.
                  </Typography>
                  <Typography variant="body1">
                    (c) Customer and Red Roots may agree to additional services pursuant
                    to the Subscription Agreement and any Orders entered into pursuant thereto.
                    Any such agreement must be in writing.
                  </Typography>
                </Box>
              </Box>

              <Box sx={{ mb: 3 }}>
                <Typography variant="h6" gutterBottom fontWeight="bold">
                  5. Customers' Responsibilities, Representations and Warranties.
                </Typography>
                <Box sx={{ ml: 2 }}>
                  <Typography variant="body1" gutterBottom>
                    (a) <strong>Customer Content</strong>: Customer
                    represents and warrants that he, she, or it owns all rights to any information
                    or data it, he, or she provides in relation to any Application, the Website or
                    the Software (the "Customer Content") or otherwise has and will continue to
                    hold all rights and permissions necessary to legally use, share, display,
                    transfer and/or license any Customer Content in any manner contemplated by, in
                    furtherance of or pursuant to this Agreement. Customer further represents and
                    warrants that Red Roots's use of any Customer Content in the provision of any
                    Service(s) will not infringe or violate the right(s) of any third party,
                    including without limitation, any copyrights, trademarks, privacy rights,
                    contract rights or any other intellectual property or proprietary rights.
                  </Typography>
                  <Typography variant="body1" gutterBottom>
                    (b) <strong>Compliance With Law</strong>: You represent, warrant, and
                    agree that You have and shall continue to comply with any and all laws, rules
                    or regulations applicable to Your use or receipt of the Software.
                  </Typography>
                  <Typography variant="body1" gutterBottom>
                    (c) <strong>Compliance With Safety Rules and Regulations</strong>: You
                    represent, warrant, and agree that You will comply with all of Customer's applicable
                    safety rules, policies, procedures, or regulations (collectively, the "Safety
                    Policies") when using the Software. To the extent the Software's use
                    contradicts any Safety Policy, You shall follow and comply with the applicable
                    Safety Policy(ies) and disregard any instructions, advice or feedback from the
                    Software.
                  </Typography>
                  <Typography variant="body1" gutterBottom>
                    (d) <strong>Security</strong>. Customer is responsible for the security of its
                    own data, any data on or relating to the Software and the Website. Red Roots
                    is not responsible to Customer or to any third party for unauthorized access to
                    any data or the unauthorized use of any Customer Content, Customer information
                    or any data or information belonging to Customer by any third party unless the
                    unauthorized use resulted from Red Roots's negligent failure to take
                    commercially reasonable steps to protect such data. The Customer is responsible
                    for the use of the Software by any of its employees, agents or representatives
                    or any person to whom it provides access to the Software or any person who
                    gains access to the Software as a result of its failure to use reasonable
                    security precautions, even if such use was not authorized by the Customer.
                  </Typography>
                  <Typography variant="body1" gutterBottom>
                    (e) <strong>Payment of Service Fees</strong>. All Fees and any invoices, if
                    any, are due upon receipt.
                  </Typography>
                  <Typography variant="body1" gutterBottom>
                    (f) <strong>Use of Software and Software.</strong> Customer shall not, and
                    shall not permit anyone to: (i) copy or republish the Software or the Software,
                    (ii) make the Software available to any person other than authorized Customer
                    users, (iii) modify or create derivative works based upon the Software or any
                    documentation provided to Customer by Red Roots (the "Documentation"), (iv)
                    remove, modify or obscure any copyright, trademark or other proprietary notices
                    contained in the software used to provide the Software or in the Documentation,
                    (v) reverse engineer, decompile, disassemble, or otherwise attempt to derive
                    the source code of the Software used to provide the Software, except and only
                    to the extent such activity is expressly permitted by applicable law, or (vi)
                    access the Software or use the Documentation in order to build a similar
                    product or competitive product. Subject to the limited licenses granted herein,
                    Red Roots shall own all right, title and interest in and to the Software,
                    relativity applications, scripts, services, Documentation, and other deliverables
                    provided under this Agreement, including all modifications, improvements,
                    upgrades, derivative works and feedback related thereto and intellectual
                    property rights therein. Customer agrees to assign all right, title and
                    interest it may have in the foregoing to Red Roots.
                  </Typography>
                  <Typography variant="body1" gutterBottom>
                    (g) <strong>Seat Access.</strong> Customer is responsible for the security of
                    its Seat access information. Customer may not permit more than one person to
                    use a specific Seat. Customer is responsible for any person to whom Customer
                    provides access to the Software. Customer, and any person or entity that
                    accesses the Software by or through Customer's Seat, are bound by these Terms.
                  </Typography>
                  <Typography variant="body1">
                    (h) <strong>Validation.</strong> Customer is responsible for its use of the
                    Software and the validation of any Software outputs whether made via the
                    Software interface or via document(s) generated by the Software.
                  </Typography>
                </Box>
              </Box>

              <Box sx={{ mb: 3 }}>
                <Typography variant="h6" gutterBottom fontWeight="bold">
                  6. Intellectual Property Rights.
                </Typography>
                <Box sx={{ ml: 2, textAlign: "justify" }}>
                  <Typography variant="body1">
                    Red Roots and its Software are protected by copyright, trademark and other state, national and
                    international statutory, common law and other legal frameworks. Other than
                    expressly set forth herein, this Agreement does not grant Customer any right,
                    title or interest in Red Roots, the Software or any property belonging to Red
                    Roots including, without limitation, its trademarks, logos, Softwares, text,
                    photos, images, graphics, audio, video or other materials or programming
                    provided in relation to the Software or otherwise (collectively, "Red Roots'
                    Materials"). Customer may not change, translate or otherwise create derivative
                    works of the Software or Red Roots' Materials. Further, Red Roots shall own
                    any and may utilize in its sole discretion any and all feedback, information,
                    recommendations or improvements to its materials whether they originate from
                    the Customer or otherwise.
                  </Typography>
                </Box>
              </Box>

              <Box sx={{ mb: 3 }}>
                <Typography variant="h6" gutterBottom fontWeight="bold">
                  7. Third-Party Restrictions.
                </Typography>
                <Box sx={{ ml: 2, textAlign: "justify" }}>
                  <Typography variant="body1">
                    Third-party technology that may
                    be appropriate or necessary for use with some Software Services is specified in
                    the Software documentation, as applicable. Customer's right to use such
                    third-party technology is governed by the terms of the third party's technology
                    license agreement and not under the Agreement.
                  </Typography>
                </Box>
              </Box>

              <Box sx={{ mb: 3 }}>
                <Typography variant="h6" gutterBottom fontWeight="bold">
                  8. Additional Rights.
                </Typography>
                <Box sx={{ ml: 2, textAlign: "justify" }}>
                  <Typography variant="body1">
                    Subject to any restrictions in these
                    Terms of Use, Red Roots hereby authorizes Customer to view, copy, download and
                    print a single copy of the information and data ("Content") available on the
                    Website, provided that: (1) the Content is used solely for personal,
                    noncommercial purposes; (2) the Content is not modified, republished, or redistributed;
                    and (3) all copyright, trademark, service mark and other proprietary notices
                    are reproduced as they appear in any such Content. Without limiting the
                    generality of the foregoing, Customer is only permitted to use the Website to
                    utilize Software offered by Red Roots. Except as expressly provided above,
                    nothing contained herein shall be construed as conferring, by implication,
                    estoppel or otherwise, any license or right under any patent, trademark or
                    copyright of Red Roots.
                  </Typography>
                </Box>
              </Box>

              <Box sx={{ mb: 3 }}>
                <Typography variant="h6" gutterBottom fontWeight="bold">
                  9. Competitor Exclusion.
                </Typography>
                <Box sx={{ ml: 2, textAlign: "justify" }}>
                  <Typography variant="body1">
                    Notwithstanding the grant of access
                    conferred in Section 4, Red Roots expressly and without limitation revokes the
                    right of any competitor to access the Website or the Software in any way and
                    for any purpose, including, but not limited to, through employees, officers,
                    directors, third party agents, affiliates, or independent contractors. If Customer
                    a Red Roots competitor, Customer acknowledges that Customer is accessing the
                    Website or the Software without legal authorization, and Customer agrees to
                    immediately discontinue such access, and to direct all parties within its, his
                    or her control or under Customer's direction, including, but not limited to,
                    employees, officers, directors, third party agents, affiliates, or independent
                    contractors, to cease accessing the Software and the Website on Customer's
                    behalf, or for Customer's benefit.
                  </Typography>
                </Box>
              </Box>

              <Box sx={{ mb: 3 }}>
                <Typography variant="h6" gutterBottom fontWeight="bold">
                  10. Indemnification.
                </Typography>
                <Box sx={{ ml: 2, textAlign: "justify" }}>
                  <Typography variant="body1">
                    Customer shall defend, indemnify and
                    hold harmless Red Roots and its officers, directors, employees, agents and
                    affiliates, and their respective successors and assigns, from and against any
                    claims, losses, damages, liabilities, costs or expenses of any nature including
                    without limitation attorney's fees and costs suffered or incurred by any of
                    them to the extent caused by, arising in connection with or related directly or
                    indirectly to (a) Customer's breach, default or misrepresentation of any
                    warranty or obligation made in or created by this Agreement; (b) the Customer Content
                    provided to Red Roots pursuant to this Agreement; and (c) any actual or alleged
                    breach, violation, misappropriation or infringement of any third party Lease
                    Agreement, or any Exhibit or amendment hereto.
                  </Typography>
                </Box>
              </Box>

              <Box sx={{ mb: 3 }}>
                <Typography variant="h6" gutterBottom fontWeight="bold">
                  11. Force Majeure, Service Interruptions and Non-party data providers.
                </Typography>
                <Box sx={{ ml: 2, textAlign: "justify" }}>
                  <Typography variant="body1">
                    Neither Customer nor Red Roots shall be deemed to have breached
                    these Terms and Conditions solely as a result of any delay, failure in
                    performance or interruption of service resulting directly or indirectly from
                    any act of God, action of the elements, fire, accident, riot, strike, work
                    stoppage or other labor disturbance, interruption of power or water, act of
                    war, act of terrorism, invasion, civil commotion, enactment of laws or other
                    casualty or cause, whether similar or dissimilar, arising in a manner beyond
                    the reasonable control of the party required to perform and without such Party's
                    negligence or willful misconduct.
                  </Typography>
                </Box>
              </Box>

              <Box sx={{ mb: 3 }}>
                <Typography variant="h6" gutterBottom fontWeight="bold">
                  12. Damages Limitation.
                </Typography>
                <Box sx={{ ml: 2, textAlign: "justify" }}>
                  <Typography variant="body1" sx={{ textTransform: 'uppercase' }}>
                    Notwithstanding any provision of this
                    agreement to the contrary, Red Roots will not be liable to customer or any
                    third party for any indirect, incidental, special, punitive, exemplary or
                    consequential damages of any kind or nature including without limitation any
                    loss of use, loss of business, loss of revenue or loss of profit, even if
                    advised of the possibility of such damage or if such damage could have been
                    reasonably foreseen. Red Roots's maximum aggregate liability hereunder for any
                    claims whatsoever shall be limited to the amount paid
                    under this agreement by customer to Red Roots during the three (3) month period
                    preceding the event, occurrence or cause giving rise to any such claim, if any.
                  </Typography>
                </Box>
              </Box>

              <Box sx={{ mb: 3 }}>
                <Typography variant="h6" gutterBottom fontWeight="bold">
                  13. Warranty Disclaimer.
                </Typography>
                <Box sx={{ ml: 2, textAlign: "justify" }}>
                  <Typography variant="body1" sx={{ textTransform: 'uppercase' }}>
                    The software, comparative information
                    and all other products, software and materials provided under this agreement
                    are provided on an "as is, where is" basis without warranty of any kind. Except
                    to the extent expressly set forth herein, to the maximum extent permitted by
                    law, Red Roots expressly disclaims and excludes any and all warranties,
                    conditions, representations and guarantees with respect to the software,
                    comparative information, and all other products, software and materials
                    provided under this agreement, whether express or implied, arising by law,
                    custom, prior oral or written statements or otherwise, including without
                    limitation, any warranty of merchantability, fitness for a particular purpose,
                    title or noninfringement. No representation or other statement of fact or
                    opinion, including without limitation statements regarding efficacy,
                    suitability for use or performance of the software, or otherwise, whether made
                    by employees, agents or affiliates of Red Roots or otherwise, which are not
                    expressly set forth in this agreement, shall be deemed to be a warranty by Red
                    Roots for any purpose or give rise to any liability of Red Roots whatsoever. No
                    provision of the uniform commercial code or uniform computer information
                    transaction act in any jurisdiction shall apply to this agreement or the goods
                    or software provided hereunder.
                  </Typography>
                </Box>
              </Box>

              <Box sx={{ mb: 3 }}>
                <Typography variant="h6" gutterBottom fontWeight="bold">
                  14. No Unlawful or Prohibited Use.
                </Typography>
                <Box sx={{ ml: 2, textAlign: "justify" }}>
                  <Typography variant="body1">
                    As a condition of Customer's
                    use of the Website or the Software, Customer agrees to comply with all
                    applicable laws, statutes, ordinances and regulations regarding or relating to
                    the Software or Red Roots. Customer warrants that it will not use the Software
                    or permit the Software to be used in any way prohibited by any applicable law,
                    statute, ordinance and regulation or these Terms and Conditions. In addition, Customer
                    warrants that Customer is eighteen years or older and is legal competent to
                    enter into a contract.
                  </Typography>
                </Box>
              </Box>

              <Box sx={{ mb: 3 }}>
                <Typography variant="h6" gutterBottom fontWeight="bold">
                  15. Governing Law; Venue.
                </Typography>
                <Box sx={{ ml: 2, textAlign: "justify" }}>
                  <Typography variant="body1">
                    This Agreement shall be governed,
                    interpreted, construed and enforced in accordance with the substantive laws of
                    the State of Indiana, without regard to conflict of laws principles. The
                    parties hereto irrevocably consent to and waive any objection to the exclusive
                    jurisdiction and venue of the courts of Indiana with respect to any and all
                    actions related to this Agreement, the interpretation of this Agreement or the
                    enforcement hereof, and the parties hereto hereby irrevocably waive any and all
                    objections thereto.
                  </Typography>
                </Box>
              </Box>

              <Box sx={{ mb: 3 }}>
                <Typography variant="h6" gutterBottom fontWeight="bold">
                  16. Right to Publish.
                </Typography>
                <Box sx={{ ml: 2, textAlign: "justify" }}>
                  <Typography variant="body1">
                    Upon Customer's selection of a
                    Subscription Package, Customer agrees that Red Roots may identify Customer as a
                    Customer and use Customer's logo and trademark in Red Roots' promotional
                    materials. Customer may request that Red Roots stop doing so by revoking this
                    right to publish in writing at any time. Customer acknowledges that it may take
                    Red Roots up to 30 days to process such request. Notwithstanding anything
                    herein to the contrary, Customer acknowledges that Red Roots may disclose the
                    existence and terms and conditions of this Agreement to its advisors, actual
                    and potential sources of financing and to third parties for purposes of due
                    diligence.
                  </Typography>
                </Box>
              </Box>

              <Box sx={{ mb: 3 }}>
                <Typography variant="h6" gutterBottom fontWeight="bold">
                  17. No Assignment; Amendment; Waiver.
                </Typography>
                <Box sx={{ ml: 2, textAlign: "justify" }}>
                  <Typography variant="body1">
                    This Agreement shall
                    inure to the benefit of and be binding upon both parties and their respective
                    successors and permitted assigns. Except as set forth herein, neither Party may
                    assign their rights or delegate their responsibilities under this Agreement to
                    any third party without the prior written consent of the other Party; provided,
                    however, that a Party may assign its rights and delegate its responsibilities
                    under this Agreement to any successor to all or substantially all of the
                    business of the assigning/delegating party if the assignee/delegee expressly
                    assumes the obligations of the assigning/delegating party or by operation of
                    law or in writing. A waiver or consent, express or implied, to or of any breach
                    or default by any party in the performance by that party of its obligations
                    with respect to this Agreement is not a consent or waiver to or of any other
                    breach or default in the performance by that Party of the same or any other
                    obligations. Failure on the part of a Party to complain of any act or omission
                    of any other Party or to declare any other Party in default with respect to
                    this Agreement, irrespective of how long that failure continues, does not
                    constitute a waiver by that party of its rights with respect to that default.
                  </Typography>
                </Box>
              </Box>

              <Box sx={{ mb: 3 }}>
                <Typography variant="h6" gutterBottom fontWeight="bold">
                  18. No Waiver.
                </Typography>
                <Box sx={{ ml: 2, textAlign: "justify" }}>
                  <Typography variant="body1">
                    No failure or delay by either Party to
                    exercise any right under this Agreement, nor any partial exercise of any right,
                    will constitute a waiver of such right.
                  </Typography>
                </Box>
              </Box>

              <Box sx={{ mb: 3 }}>
                <Typography variant="h6" gutterBottom fontWeight="bold">
                  19. Integration and Affirmation.
                </Typography>
                <Box sx={{ ml: 2, textAlign: "justify" }}>
                  <Typography variant="body1">
                    Customer acknowledges and
                    agree that these Terms of Use, and the other policies, terms, guidelines and
                    rules referenced herein, constitute the entire and exclusive agreement between Red
                    Roots and Customer regarding the Website and the Software. These terms and
                    conditions supersede and replace any prior agreements between Red Roots and Customer
                    regarding the Software.
                  </Typography>
                </Box>
              </Box>

              <Box sx={{ textAlign: "justify", mt: 4 }}>
                <Typography variant="body1" fontWeight="bold">
                  Last Updated: October 28, 2024
                </Typography>
              </Box>
            </Container>
          </Paper>
          <Box sx={{ position: "fixed", bottom: 20, left: 20 }}>
            <Typography variant="body2" color="textSecondary">
              &copy;2024 Red Roots Technologies
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default TermsRoute;