import { AccordionCard, AccordionCardProps } from "./AccordionCard";
import { DraggableIndicator } from "./DraggableIndicator";
import { DraggableBox } from "./DraggableBox";
import { useSession } from "../hooks/useSession";

interface AccordionCardDraggableProps
  extends AccordionCardProps<typeof DraggableBox> {
    preventDrag?: boolean;
  }

export const AccordionCardDraggable: React.FC<AccordionCardDraggableProps> = ({
  dragId,
  startActions,
  preventDrag,
  ...props
}) => {
  const { user } = useSession();
  return (
    <AccordionCard
      {...props}
      component={DraggableBox}
      dragId={dragId}
      startActions={
        (user?.isRetailUser || preventDrag) ? null : <DraggableIndicator dragId={dragId}>{startActions}</DraggableIndicator>
      }
      accordionSx={{
        ...props.accordionSx,
        "&.DraggableBox--dragging .MuiAccordionSummary-root:hover": {
          cursor: "grabbing",
        },
      }}
    />
  );
};
