import React from "react";
import { Typography } from "@mui/material";
import { useApiRequest } from "../../hooks/useApiRequest";
import { Subgroup } from "../../types/subgroup";
import { ConfirmModal } from "../../components/ConfirmModal";
import { LoadingOverlay } from "../../components/LoadingOverlay";

type AddSubGroupToQuotesTemplatesModalProps = {
  onClose: () => void;
  subgroup?: Subgroup;
};

export const AddSubGroupToQuotesTemplatesModal: React.FC<AddSubGroupToQuotesTemplatesModalProps> = ({
  onClose,
  subgroup,
}) => {

  const {
    data: addData,
    loading: isAdding,
    request: addRequest,
    errorMessage: addErrorMessage
  } = useApiRequest<any>(true);

  const handleAddClick = () => {
    if (subgroup) {
      addRequest(`/company/options/subgroups/${subgroup.subgroupGuid}/addtoquotes`, {
        method: "GET",
      });
    }
  };


  return (
    <>
      {isAdding && <LoadingOverlay />}
      {addErrorMessage ? (
        <ConfirmModal
          isOpen={true}
          heading={
            `An error has occurred.`
          }
          body={
            <Typography variant="body2" color="grey.500">
              {addErrorMessage}
            </Typography>
          }
          confirmButtonText="Ok"
          confirmOnly={true}
          onConfirm={onClose}
          onClose={onClose}
        />
      ) : addData ? (
        <ConfirmModal
          isOpen={true}
          heading={addData.title}
          body={
            <Typography variant="body2" color="grey.500">
              {addData.message}
            </Typography>
          }
          confirmButtonText="Ok"
          confirmOnly={true}
          onConfirm={onClose}
          onClose={onClose}
        />
      ) : (
        <ConfirmModal
          isOpen={true}
          heading={
            `Add "${subgroup?.subgroupName ?? ''}" to Quotes & Templates`
          }
          body={
            <Typography variant="body2" color="grey.500">
              This will add the subgroup to all applicable Quotes and Templates. The subgroup needs to have valid
              options for the Quote/Template series, and the start and end date of the subgroup and valid option must be
              within the Quote price date.
            </Typography>
          }
          confirmButtonText="Add"
          onConfirm={handleAddClick}
          onClose={onClose}
          confirmDisabled={isAdding}
        />        
      )}
    </>
  );
};
