import { Chip, Grid, Stack, Typography, InputAdornment } from "@mui/material";
import { type OrderCardCustomSubgroup as OrderCardCustomSubgroupType } from "../../types/orderCardCustomSubgroup";
import { OrderCardBase } from "./OrderCardBase";
import InputField from "../../components/InputField";
import { Controller, useForm } from "react-hook-form";
import MultiSelectField from "../../components/MultiSelectField";
import { OptionRoom, getOptionRoomOptions } from "../../types/optionRoom";
import { useOrderCard } from "./OrderCardContext";
import { useEffect, useMemo, useState, Dispatch, SetStateAction } from "react";
import { ConfirmDeleteModal } from "./ConfirmDeleteModal";
import { isValidCurrency, isValidNumber } from "../../utils/validation";
import { OrderCardApproveDenyButtons } from "./OrderCardApproveDenyButtons";
import { getOrderCardError } from "../../types/orderCard";
import { Order } from "../../types/order";
import { formatAsCurrency } from "../../utils/number";
import { OrderCardEditResponse } from "./orderCardEditResponse";
import { OrderCard } from "../../types/orderCard";
import { OrderCardActionButton } from "./OrderCardActionButton";
import { useApiRequest } from "../../hooks/useApiRequest";
import { useGlobalToastNotificationContext } from "../../context/GlobalToastNotificationContext";
import { LoadingOverlay } from "../../components/LoadingOverlay";
import produce from "immer";
import { useSession } from "../../hooks/useSession";

interface OrderCardCustomSubgroupProps {
  order: Order;
  orderCard: OrderCardCustomSubgroupType;
  expanded: boolean;
  groupIndex: number;
  refreshOrder: () => void;
  onOrderCardEdited: (
    orderCardEditResponse: OrderCardEditResponse<OrderCard>
  ) => void;
  setOrder: Dispatch<SetStateAction<Order | null>>;
}

type FormData = {
  subgroupName: string;
  quantity: number | null;
  price: number | null;
  rooms: OptionRoom[];
  color: string | null;
  notes: string | null;
};

const getDefaultValues = (orderCard: OrderCardCustomSubgroupType): FormData => {
  return {
    subgroupName: orderCard?.subgroupName ?? "",
    quantity: orderCard?.cardData.quantity ?? null,
    price: orderCard?.cardData.price ?? null,
    rooms: orderCard?.cardData.rooms ?? [],
    color: orderCard?.cardData.color ?? null,
    notes: orderCard?.cardData.notes ?? null,
  };
};

export const OrderCardCustomSubgroup: React.FC<
  OrderCardCustomSubgroupProps
> = ({ order, orderCard, expanded, groupIndex, refreshOrder, onOrderCardEdited, setOrder }) => {
  const { setEditingOrderCard } = useOrderCard();
  const [pendingDeletion, setPendingDeletion] = useState(false);
  const [pendingDenial, setPendingDenial] = useState(false);
  const [groupInvalid, setGroupInvalid] = useState(false);
  const { user } = useSession();

  const disabled = orderCard.isLocked;

  const {
    register,
    formState,
    control,
    setValue,
    getValues,
    setError,
    watch,
    reset,
  } = useForm<FormData>({
    defaultValues: getDefaultValues(orderCard),
    reValidateMode: "onChange",
    mode: "onChange",
  });
  const quantity = watch("quantity");
  const price = watch("price");

  const [subgroupPlaceholderText, setSubgroupPlaceholderText] = useState("");

  useEffect(() => {
    if (orderCard.orderCardType === "cs") {
      setSubgroupPlaceholderText(`Enter Custom ${orderCard.groupName} Option`);
    }
  }, [orderCard.orderCardType, orderCard.groupName]);

  const {
    request: saveOrderCardRequest,
  } = useApiRequest<OrderCardEditResponse<OrderCard>>(false, "orderCard");

  const handleBlurField = () => {
    const { subgroupName, ...fields } = getValues();

    saveOrderCardRequest(`/ordercards/${orderCard.orderCardGuid}/edit`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      data: { 'subgroupName': subgroupName, 'cardData': {
        ...fields,
        quantity:
          fields.quantity != null && !formState.errors.quantity
            ? fields.quantity
            : null,
        price:
          fields.price != null && !formState.errors.price ? fields.price : null,
      } },
    }, {
      onSuccess: (data) => {
        onOrderCardEdited(data);
      },
      onUnsuccess() {
        //
      } 
    });
  };

  const handleEditingField = () => {
    setEditingOrderCard(orderCard);
  };

  const handleDenyClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    setPendingDenial(true);
  };

  const handleDeleteClick = () => {
    setPendingDeletion(true);
  };

  const handleDeleteConfirmation = (data: any) => {
    setOrder((prevOrder) =>
      produce(prevOrder, (draft) => {
        if (!draft || !draft.orderCards) return;
        let index = orderCard.sortOrder;
        if (draft.orderCards.length <= index || draft.orderCards[index].orderCardGuid !== orderCard.orderCardGuid) {
          index = draft.orderCards.findIndex((dcard) => dcard.orderCardGuid === orderCard.orderCardGuid);
        }
        draft.orderCards.splice(index,1);
        if (data.orderCardSort && data.orderCardSort.length > 0) {
          for(let i=0; i<data.orderCardSort.length; i++) {
            const dindex = draft.orderCards.findIndex((dcard) => dcard.orderCardGuid === data.orderCardSort[i].orderCardGuid);
            if (dindex >= 0) draft.orderCards[dindex].sortOrder = data.orderCardSort[i].sortOrder;
          }
        }
        if (data.relatedOrderCards && data.relatedOrderCards.length > 0) {
          data.relatedOrderCards.forEach((card: OrderCard) => {
            let x2 = card.sortOrder;
            if (draft.orderCards!.length <= x2 || draft.orderCards![x2].orderCardGuid !== card.orderCardGuid) {
              x2 = draft.orderCards!.findIndex((dcard) => dcard.orderCardGuid === card.orderCardGuid);
            }
            draft.orderCards![x2] = card;
          });
        }
        draft.saveAsOptions = data.saveAsOptions;
      })
    );

    setPendingDeletion(false);
  };

  const roomMenuOptions = useMemo(
    () => getOptionRoomOptions(orderCard.rooms),
    [orderCard.rooms]
  );

  const orderCardTotal = useMemo(() => {
    if (quantity == null || price == null || isNaN(quantity) || isNaN(price)) {
      return "Enter info to calculate price";
    }

    const totalPrice = orderCard.totalPrice ?? 0;
    const totalFormatted = formatAsCurrency(totalPrice);
    return totalPrice > 0 ? `+${totalFormatted}` : totalFormatted;
  }, [orderCard.totalPrice, price, quantity]);

  useEffect(() => {
    reset(getDefaultValues(orderCard), { keepDirtyValues: true });
  }, [orderCard, reset]);

  useEffect(() => {
    if (orderCard.errors.length === 0) return;

    const orderCardGroupError = getOrderCardError(orderCard, "ecsg");
    const orderCardRoomsError = getOrderCardError(orderCard, "ecsr");

    if (!!orderCardGroupError) {
      setGroupInvalid(true);
    } else {
      setGroupInvalid(false);
    }

    if (!!orderCardRoomsError) {
      setError("rooms", { type: "manual", message: "Inactive" });
    }
  }, [disabled, orderCard, setError]);

  const {
    data: addCustomOptionData,
    status: addCustomOptionStatus,
    loading: addCustomOptionLoading,
    request: addCustomOptionRequest,
    errorMessage: addCustomOptionErrorMessage,
  } = useApiRequest<any>();

  const [isLoading, setIsLoading] = useState(false);
  const handleAddCustomOptionClick = () => {
    setIsLoading(true);
    addCustomOptionRequest(`/orders/${order.orderGuid}/subgroups/add`, {
      method: "POST",
      data: {
        groupGuid: orderCard.groupGuid,
      },
    });
  };

  useEffect(() => {
    if (addCustomOptionStatus === "ok" && addCustomOptionData) {
      setOrder((prevOrder) =>
        produce(prevOrder, (draft) => {
          if (!draft || !draft.orderCards) return;
          // When we add a card, first we need to update the sort orders of the existing cards
          if (addCustomOptionData.orderCardSort && addCustomOptionData.orderCardSort.length > 0) {
            for(let i=0; i<addCustomOptionData.orderCardSort.length; i++) {
              const dindex = draft.orderCards.findIndex((dcard) => dcard.orderCardGuid === addCustomOptionData.orderCardSort[i].orderCardGuid);
              if (dindex >= 0) draft.orderCards[dindex].sortOrder = addCustomOptionData.orderCardSort[i].sortOrder;
            }
          }

          // Now we should have a hole in the sort order
          const insertAfterIndex = draft.orderCards.findIndex((dcard) => dcard.sortOrder === addCustomOptionData.orderCard.sortOrder-1);
          if (insertAfterIndex >= 0) {
            draft.orderCards.splice(insertAfterIndex+1, 0, addCustomOptionData.orderCard);
          }

          draft.saveAsOptions = addCustomOptionData.saveAsOptions;
        })
      );
    }
    setIsLoading(false);
  }, [addCustomOptionStatus, setIsLoading, setOrder, addCustomOptionData]);

  const { showErrorToast } = useGlobalToastNotificationContext();
  useEffect(() => {
    if (addCustomOptionErrorMessage) {
      showErrorToast({ message: addCustomOptionErrorMessage });
    }
  }, [addCustomOptionErrorMessage, showErrorToast]);

  const [showApprovals, setShowApprovals] = useState(false);
  useEffect(() => {
    if (!order || !order.orderCards) return;
    let index = orderCard.sortOrder;
    if (order.orderCards.length <= index || order.orderCards[index].orderCardGuid !== orderCard.orderCardGuid) {
      index = order.orderCards.findIndex((dcard) => dcard.orderCardGuid === orderCard.orderCardGuid);
    }

    setShowApprovals(order.isTemplate === false &&
      order.orderCards[index].orderCardStatus === "c" &&
      order.orderCards[index].isApproved === false);
  },[order, orderCard.orderCardGuid, orderCard.sortOrder]);

  return (
    <>
    {isLoading && <LoadingOverlay />}

      <OrderCardBase
        disabled={disabled}
        orderCard={orderCard}
        onDeleteClick={handleDeleteClick}
        expanded={expanded}
        groupError={groupInvalid ? "Inactive" : undefined}
        groupIndex={groupIndex}
        heading={
          <Stack>
            <Stack direction="row" alignItems="center" spacing={1}>
              <Typography variant="body1">
                {orderCard.subgroupName !== ""
                  ? orderCard.subgroupName
                  : subgroupPlaceholderText}
              </Typography>
              <Chip
                label={`Custom ${orderCard.groupName}`}
                size="small"
                color="primary"
                variant="filled"
                sx={({ palette }) => ({
                  backgroundColor: palette.primary.light,
                  color: palette.primary.main,
                })}
              />
            </Stack>
            <Typography variant="body2">{orderCardTotal}</Typography>
          </Stack>
        }
        endActions={
          showApprovals ? (
            <OrderCardApproveDenyButtons
              orderCard={orderCard}
              refreshOrder={refreshOrder}
              onOrderCardEdited={onOrderCardEdited}
              denyButtonText={user?.isRetailUser ? "Delete" : "Deny"}
              onDenyClick={handleDenyClick}
              allUsersCanDeny={true}
            />
          ) : undefined
        }
      >
        <Grid container spacing={2} component="form">
          <Grid xs={6} sm={6} md={6} item>
            <InputField
              {...register("subgroupName", {
                onBlur: () => handleBlurField(),
              })}
              label="Custom Option"
              onFocus={handleEditingField}
              placeholder={subgroupPlaceholderText}
              required
              fullWidth
              error={!!formState.errors.subgroupName}
              helperText={formState.errors.subgroupName?.message}
            />
          </Grid>
          <Grid xs={3} sm={3} md={3} item>
            <InputField
              {...register("quantity", {
                onBlur: () => handleBlurField(),
                validate: {
                  isNumber: (value) => {
                    if (value == null) return true;
                    if (!isValidNumber(value) || value < 0) {
                      return "Invalid quantity";
                    }
                  },
                },
                valueAsNumber: true,
              })}
              type="number"
              label="Quantity"
              onFocus={handleEditingField}
              placeholder="Enter Quantity"
              required
              fullWidth
              error={!!formState.errors.quantity}
              helperText={formState.errors.quantity?.message}
              intOnly={true}
            />
          </Grid>
          <Grid xs={3} sm={3} md={3} item>
            <InputField
              {...register("price", {
                onBlur: () => handleBlurField(),
                validate: {
                  isNumber: (value) => {
                    if (!isValidCurrency(value)) return "Invalid price";
                  },
                },
                valueAsNumber: true,
              })}
              type="number"
              label="Price"
              onFocus={handleEditingField}
              placeholder=" Enter Price"
              required
              fullWidth
              startAdornment={
                <InputAdornment position="start">$</InputAdornment>
              }
              intOnly={true}
              error={!!formState.errors.price}
              helperText={formState.errors.price?.message}
            />
          </Grid>
          {orderCard.categoryName !== 'Factory Trim Out' && (
            <Grid xs={12} sm={12} md={12} item>
              <Controller
                control={control}
                name="rooms"
                render={({ field, fieldState }) => (
                  <MultiSelectField
                    {...field}
                    label="Rooms"
                    onBlur={() => handleBlurField()}
                    onFocus={handleEditingField}
                    placeholder="Select"
                    fullWidth
                    options={roomMenuOptions}
                    error={!!fieldState.error}
                    helperText={fieldState.error?.message}
                    onDelete={(option) => {
                      const newOptionRooms = orderCard.cardData.rooms.filter(
                        (s) => s !== option.value
                      );
                      setValue("rooms", newOptionRooms);
                      handleBlurField();
                    }}
                  />
                )}
              />
            </Grid>
          )}
          <Grid xs={12} sm={12} md={12} item>
            <InputField
              {...register("color", { onBlur: handleBlurField })}
              label="Color"
              onFocus={handleEditingField}
              placeholder="Enter Color"
              fullWidth
              disabled={disabled}
              error={!!formState.errors.color}
              helperText={formState.errors.color?.message}
            />
          </Grid>
          <Grid item xs={12} md={12}>
            <InputField
              {...register("notes", {
                onBlur: () => handleBlurField(),
              })}
              fullWidth
              required
              onFocus={handleEditingField}
              label="Notes"
              placeholder="Enter notes..."
              multiline
              rows={1}
            />
          </Grid>
          <Grid item>
            <OrderCardActionButton
              onClick={handleAddCustomOptionClick}
              disabled={addCustomOptionLoading}
            >
              Custom Option
            </OrderCardActionButton>
          </Grid>
        </Grid>
      </OrderCardBase>
      {(pendingDeletion || pendingDenial) && (
        <ConfirmDeleteModal
          orderCard={orderCard}
          action={pendingDeletion ? "delete" : "deny"}
          onDelete={handleDeleteConfirmation}
          onCancel={() => {
            setPendingDeletion(false);
          }}
        />
      )}
    </>
  );
};
