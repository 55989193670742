import { formatAsDate } from "../utils/date";
import { formatAsCurrency } from "../utils/number";

export const reportDataType = {
  /**
   * displayed typically with $123,456.78 but sometimes $123K as defined in a report. Sorted as a numeric value
   */
  amount: "a",
  /**
   * displayed 123. Sorted numerically.
   */
  int: "i",
  /**
   * displayed as is. Sorted as case-insensitive.
   */
  string: "s",
  /**
   * displayed e.g. Jan 1, 2024 but sorted as a timestamp (int) value.
   */
  timestamp: "t",
} as const;

export type ReportDataType =
  (typeof reportDataType)[keyof typeof reportDataType];

export const formatReportData = (
  data: string | number | null,
  dataType: ReportDataType
): string | number | null => {
  switch (dataType) {
    case reportDataType.amount: {
      const dataNumber = typeof data === "string" ? parseInt(data) : data;
      return dataNumber ? formatAsCurrency(dataNumber) : null;
    }
    case reportDataType.int: {
      return data;
    }
    case reportDataType.string: {
      return data;
    }
    case reportDataType.timestamp: {
      const dataNumber = typeof data === "string" ? parseInt(data) : data;
      return formatAsDate(dataNumber);
    }
    default: {
      const _exhaustiveCheck: never = dataType;
      return _exhaustiveCheck;
    }
  }
};
