import React, { useCallback, useEffect, useMemo, useState, Dispatch, SetStateAction } from "react";
import { useApiRequest } from "../../hooks/useApiRequest";
import { Order } from "../../types/order";
import { OrderCardActionButton } from "./OrderCardActionButton";
import Grid from "@mui/material/Unstable_Grid2"; // Grid version 2
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import {
  orderCardSubgroupOptionHasLogicErrors,
  type OrderCardSubgroupOption as OrderCardSubgroupOptionType,
} from "../../types/orderCardSubgroupOption";
import { OrderCardBase } from "./OrderCardBase";
import InputField from "../../components/InputField";
import SelectField, { SelectFieldProps } from "../../components/SelectField";
import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  InputAdornment,
  Typography,
} from "@mui/material";
import {
  getOptionOptionRoomOptions,
  getOptionPricePerShortDisplayName,
  isOptionNonRoomOption,
  Option,
} from "../../types/option";
import { useOrderCard } from "./OrderCardContext";
import { Controller, useForm } from "react-hook-form";
import MultiSelectField from "../../components/MultiSelectField";
import { OrderCardSubgroupOptionAttributeField } from "./OrderCardSubgroupOptionAttributeField";
import { getOptionRoomOptions, OptionRoom } from "../../types/optionRoom";
import { formatAsCurrency } from "../../utils/number";
import { useGlobalToastNotificationContext } from "../../context/GlobalToastNotificationContext";
import { ConfirmDeleteModal } from "./ConfirmDeleteModal";
import { OrderCardApproveDenyButtons } from "./OrderCardApproveDenyButtons";
import { ConfirmModal } from "../../components/ConfirmModal";
import { Attribute } from "../../types/attribute";
import { AttributeSelection } from "../../types/attributeSelection";
import { getOrderCardError } from "../../types/orderCard";
import { formatAsDate } from "../../utils/date";
import { DeepPartial } from "../../utils/deepPartial";
import { OrderCardEditResponse } from "./orderCardEditResponse";
import { OrderCard } from "../../types/orderCard";
import produce from "immer";
import { LoadingOverlay } from "../../components/LoadingOverlay";
import { useSession } from "../../hooks/useSession";

/*
  This tracks handleBlur (saving data) API calls. It has to be held outside of the render.
  Each call to the API increments this counter, and each response, error or not, decrements
  it. When it reaches 0, the API response is saved into order.orderCards. Without this, the
  option will update based on the response after every call, but it might lose data as it is
  entered due to the API setting some values. Better to let the UI continue to work and just
  make sure we have the last saved state reflected in the UI.
*/
let savedTimestamp = 0;

const CustomOptionSelectOption = {
  label: "Custom Option",
  value: "Custom Option",
};

type FormData = {
  customOptionSelected: boolean;
  selectedOptionGuid: string | null;
  quantity: number | null;
  selectedRooms: OptionRoom[];
  roomsAndQuantities: {
    roomName: OptionRoom;
    quantity: number;
  }[];
  attributeSelections: Record<
    Attribute["attributeGuid"],
    AttributeSelection["attributeselectionGuid"]
  >;
  notes: string | null;
  price: number | null;
  color: string | null;
};

const getDefaultValues = (
  orderCard: DeepPartial<OrderCardSubgroupOptionType> | null
): FormData => {
  const customOptionSelected =
    orderCard?.cardData?.customOptionSelected ?? false;

  const selectedOptionGuid = customOptionSelected
    ? CustomOptionSelectOption.value
    : orderCard?.cardData?.selectedOptionGuid ?? "";

  const selectedOption = orderCard?.selectionOptions?.find(
    (option) => option.optionGuid === selectedOptionGuid
  );
  const isRoomAndQuantity = selectedOption ? selectedOption.optionPricedPer === 'e' && !isOptionNonRoomOption(selectedOption) : false;

  // Set price field value
  const price = customOptionSelected
    ? orderCard?.cardData?.price ?? 0
    : selectedOption?.optionPricing[0].price ?? 0;

  return {
    customOptionSelected,
    selectedOptionGuid,
    quantity: orderCard?.cardData?.quantity ?? null,
    roomsAndQuantities: orderCard?.cardData?.roomsAndQuantities ?? [],
    selectedRooms: isRoomAndQuantity ? (orderCard?.cardData?.roomsAndQuantities ?? []).map((rq) => rq.roomName) : orderCard?.cardData?.selectedRooms ?? [],
    attributeSelections: (
      orderCard?.cardData?.attributeSelections ?? []
    ).reduce((curr, attributeSelection) => {
      const key = attributeSelection.attributeGuid;
      if (!curr[key]) {
        curr[key] = attributeSelection.attributeselectionGuid;
      }
      return curr;
    }, {} as FormData["attributeSelections"]),
    notes: orderCard?.cardData?.notes ?? null,
    price,
    color: orderCard?.cardData?.color ?? null,
  };
};

interface OrderCardSubgroupOptionProps {
  order: Order;
  orderCard: OrderCardSubgroupOptionType;
  expanded: boolean;
  groupIndex: number;
  refreshOrder: () => void;
  onOrderCardEdited: (
    orderCardEditResponse: OrderCardEditResponse<OrderCard>
  ) => void;
  setOrder: Dispatch<SetStateAction<Order | null>>;
}

export const OrderCardSubgroupOption: React.FC<
  OrderCardSubgroupOptionProps
> = ({ order, orderCard, expanded, refreshOrder, groupIndex, onOrderCardEdited, setOrder }) => {
  const [pendingDeletion, setPendingDeletion] = useState(false);
  const [pendingDenial, setPendingDenial] = useState(false);
  const [groupInvalid, setGroupInvalid] = useState(false);
  const [subgroupError, setSubgroupError] = useState<string | undefined>(
    undefined
  );
  const [seriesError, setSeriesError] = useState<Option | undefined>(undefined);
  const { showErrorToast } = useGlobalToastNotificationContext();
  const { setEditingOrderCard } = useOrderCard();

  const disabled = orderCard.isLocked;
  const orderCardIndex = order?.orderCards?.findIndex((dcard) => dcard.orderCardGuid === orderCard.orderCardGuid);
  const { user } = useSession();

  const [isRoomAndQuantity, setIsRoomAndQuantity] = useState(false);

  const {
    data: addCustomOptionData,
    status: addCustomOptionStatus,
    loading: addCustomOptionLoading,
    request: addCustomOptionRequest,
    errorMessage: addCustomOptionErrorMessage,
  } = useApiRequest<any>();

  const {
    data: addRoomData,
    status: addRoomStatus,
    loading: addRoomLoading,
    request: addRoomRequest,
    errorMessage: addRoomErrorMessage,
  } = useApiRequest<any>();

  const {
    data: deleteOrderCardData,
    status: deleteOrderCardStatus,
    loading: deleteOrderCardLoading,
    request: deleteOrderCardRequest,
    errorMessage: deleteOrderCardErrorMessage,
  } = useApiRequest();

  const [isLoading, setIsLoading] = useState(false);
  const handleAddCustomOptionClick = () => {
    setIsLoading(true);
    addCustomOptionRequest(`/orders/${order.orderGuid}/subgroups/add`, {
      method: "POST",
      data: {
        groupGuid: orderCard.groupGuid,
      },
    });
  };

  const {
    control,
    register,
    reset,
    formState,
    getValues,
    setValue,
    watch,
    setError,
  } = useForm<FormData>({
    defaultValues: getDefaultValues(orderCard),
    reValidateMode: "onChange",
    mode: "onChange",
  });
  const selectedOptionGuid = watch("selectedOptionGuid");
  const customOptionSelected = watch("customOptionSelected");
  const quantity = watch("quantity");
  const selectedRooms = watch("selectedRooms");
  const roomsAndQuantities = watch("roomsAndQuantities");

  useEffect(() => {
    reset(getDefaultValues(orderCard), { keepDirtyValues: true });
  }, [orderCard, reset]);

  useEffect(() => {
    if (addCustomOptionStatus === "ok" && addCustomOptionData) {
      setOrder((prevOrder) =>
        produce(prevOrder, (draft) => {
          if (!draft || !draft.orderCards) return;
          // When we add a card, first we need to update the sort orders of the existing cards
          if (addCustomOptionData.orderCardSort && addCustomOptionData.orderCardSort.length > 0) {
            for(let i=0; i<addCustomOptionData.orderCardSort.length; i++) {
              const dindex = draft.orderCards.findIndex((dcard) => dcard.orderCardGuid === addCustomOptionData.orderCardSort[i].orderCardGuid);
              if (dindex >= 0) draft.orderCards[dindex].sortOrder = addCustomOptionData.orderCardSort[i].sortOrder;
            }
          }

          // Now we should have a hole in the sort order
          const insertAfterIndex = draft.orderCards.findIndex((dcard) => dcard.sortOrder === addCustomOptionData.orderCard.sortOrder-1);
          if (insertAfterIndex >= 0) {
            draft.orderCards.splice(insertAfterIndex+1, 0, addCustomOptionData.orderCard);
          }

          draft.saveAsOptions = addCustomOptionData.saveAsOptions;
        })
      );
    }
    setIsLoading(false);
  }, [addCustomOptionStatus, setIsLoading, addCustomOptionData, setOrder]);

  useEffect(() => {
    if (addRoomStatus === "ok" && addRoomData) {
      setIsLoading(true);
      setOrder((prevOrder) =>
        produce(prevOrder, (draft) => {
          if (!draft || !draft.orderCards) return;
          // When we add a card, first we need to update the sort orders of the existing cards
          if (addRoomData.orderCardSort && addRoomData.orderCardSort.length > 0) {
            for(let i=0; i<addRoomData.orderCardSort.length; i++) {
              const dindex = draft.orderCards.findIndex((dcard) => dcard.orderCardGuid === addRoomData.orderCardSort[i].orderCardGuid);
              if (dindex >= 0) draft.orderCards[dindex].sortOrder = addRoomData.orderCardSort[i].sortOrder;
            }
          }

          // Now we should have a hole in the sort order
          const insertAfterIndex = draft.orderCards.findIndex((dcard) => dcard.sortOrder === addRoomData.orderCard.sortOrder-1);
          if (insertAfterIndex >= 0) {
            draft.orderCards.splice(insertAfterIndex+1, 0, addRoomData.orderCard);
          }

          draft.saveAsOptions = addRoomData.saveAsOptions;
        })
      );
      setIsLoading(false);
    }
  }, [addRoomStatus, addRoomData, setOrder, setIsLoading]);

  useEffect(() => {
    if (addCustomOptionErrorMessage) {
      showErrorToast({ message: addCustomOptionErrorMessage });
    }
  }, [addCustomOptionErrorMessage, showErrorToast]);

  useEffect(() => {
    if (addRoomErrorMessage) {
      showErrorToast({ message: addRoomErrorMessage });
    }
  }, [addRoomErrorMessage, showErrorToast]);

  useEffect(() => {
    if (deleteOrderCardData && deleteOrderCardStatus === "ok") {
      refreshOrder();
      setPendingDenial(false);
    }
  }, [deleteOrderCardData, deleteOrderCardStatus, refreshOrder]);

  useEffect(() => {
    if (deleteOrderCardErrorMessage) {
      showErrorToast({ message: deleteOrderCardErrorMessage });
      setPendingDenial(false);
    }
  }, [deleteOrderCardErrorMessage, showErrorToast]);

  useEffect(() => {
    if (orderCard.errors.length === 0 || disabled) {
      return;
    }

    const seriesError = getOrderCardError(orderCard, "esoos");

    setSeriesError(seriesError?.option);

    const groupError = getOrderCardError(orderCard, "esog");

    setGroupInvalid(!!groupError);

    const subgroupError = getOrderCardError(orderCard, "esos");

    const minQuantityError = getOrderCardError(orderCard, "esmq");
    const maxQuantityError = getOrderCardError(orderCard, "esxq");

    if (!!subgroupError) {
      setSubgroupError(`Exp ${formatAsDate(subgroupError.subgroup.endDate)}`);
    } else {
      setSubgroupError(undefined);
    }

    if (!!minQuantityError) {
      setError("quantity", {
        type: "manual",
        message: `${minQuantityError} Min`,
      });
    }

    if (!!maxQuantityError) {
      setError("quantity", {
        type: "manual",
        message: `${maxQuantityError} Max`,
      });
    }

    const subgroupOptionError = getOrderCardError(orderCard, "esoo");

    if (subgroupOptionError?.option?.endDate) {
      setError("selectedOptionGuid", {
        type: "manual",
        message: `Exp ${formatAsDate(subgroupOptionError.option.endDate)}`,
      });
    }
  }, [disabled, orderCard, setError]);

  // Keep totalQuantity update to date.
  const [totalQuantity, setTotalQuantity] = useState<number>(0);
  useEffect(() => {
    const newQuantity = isRoomAndQuantity ? roomsAndQuantities.reduce((sum, rq) => sum + rq.quantity, 0) : quantity ?? 0;
    setTotalQuantity(newQuantity);
  }, [isRoomAndQuantity, setTotalQuantity, quantity, roomsAndQuantities, totalQuantity]);

  const selectedOption = useMemo(() => {
    return orderCard.selectionOptions.find(
      (option) => option.optionGuid === selectedOptionGuid
    );
  }, [orderCard.selectionOptions, selectedOptionGuid]);

  useEffect(() => {
    if (selectedOption) {
      const newIsRoomAndQuantity = selectedOption.optionPricedPer === 'e' && !isOptionNonRoomOption(selectedOption);
      setIsRoomAndQuantity(newIsRoomAndQuantity);
      if (!newIsRoomAndQuantity && selectedOption.optionMinimum === selectedOption.optionMaximum) {
        setValue("quantity", selectedOption.optionMinimum);
      }
    } else {
      setIsRoomAndQuantity(false);
    }
  },[setIsRoomAndQuantity,selectedOption,setValue]);

  const attributes: Attribute[] = useMemo(() => {
    const selectedOptionAttributes = selectedOption?.attributes ?? [];
    const invalidAttribute = getOrderCardError(orderCard, "esoar")?.attribute;
    return invalidAttribute
      ? [invalidAttribute, ...selectedOptionAttributes]
      : selectedOptionAttributes;
  }, [orderCard, selectedOption]);

  const optionsFieldOptions: SelectFieldProps["options"] = useMemo(() => {
    const options: SelectFieldProps["options"] = [
      ...orderCard.selectionOptions.filter((option) => option.isHidden !== true)
        .map((option) => 
          option.hasLogicError ? getOptionOption(option, "(Logic Error)") : getOptionOption(option)
      ),

      ...(!!seriesError ? [getOptionOption(seriesError, "Invalid")] : []),
    ];

    if (orderCard.customOptionAllowed) {
      options.push(CustomOptionSelectOption);
    }
    return options;
  }, [
    orderCard.customOptionAllowed,
    orderCard.selectionOptions,
    seriesError,
  ]);

  const roomsFieldOptions = useMemo(() => {
    const invalidRooms =
      getOrderCardError(orderCard, "esor")?.invalidRooms ?? [];

    const withError = getOptionRoomOptions(invalidRooms).map((option) => ({
      ...option,
      error: "Invalid",
    }));

    return customOptionSelected
      ? getOptionRoomOptions(orderCard.rooms ?? [])
      : [...withError, ...getOptionOptionRoomOptions(selectedOption)];
  }, [orderCard, customOptionSelected, selectedOption]);

  const isNonRoomOptionSelected = useMemo(
    () => isOptionNonRoomOption(selectedOption),
    [selectedOption]
  );

  const isPriceFieldDisabled = useMemo(
    () => !customOptionSelected,
    [customOptionSelected]
  );

  const isRoomsDropdownVisible = useMemo(
    () =>
      orderCard.categoryName !== 'Factory Trim Out' &&
      (customOptionSelected ||
      (selectedOption != null && !isNonRoomOptionSelected)),
    [customOptionSelected, isNonRoomOptionSelected, selectedOption, orderCard.categoryName]
  );

  const isAttributesFieldsVisible = useMemo(
    () => !customOptionSelected,
    [customOptionSelected]
  );

  const isColorFieldVisible = useMemo(
    () => customOptionSelected,
    [customOptionSelected]
  );

  const isAddSubgroupButtonVisible = useMemo(
    () =>
      !disabled &&
      !customOptionSelected &&
      selectedOption?.uniqueAttributesPerRoom === true,
    [disabled, customOptionSelected, selectedOption?.uniqueAttributesPerRoom]
  );

  const isAddCustomOptionButtonVisible = orderCard.groupGuid && !disabled;

  const orderCardTotal = useMemo(() => {
    const totalPrice = orderCard.totalPrice ?? 0;
    const totalFormatted = formatAsCurrency(totalPrice);
    return totalPrice > 0 ? `+${totalFormatted}` : totalFormatted;
  }, [orderCard.totalPrice]);

  const hasLogicError = useMemo(
    () => orderCardSubgroupOptionHasLogicErrors(orderCard),
    [orderCard]
  );

  const optionPricedPer = useMemo(() => {
    if (selectedOption) {
      return selectedOption.optionPricedPer;
    }

    const pricedPerValues = orderCard.selectionOptions.map(
      (o) => o.optionPricedPer
    );
    const uniquePricedPerValues = Array.from(new Set(pricedPerValues));
    if (uniquePricedPerValues.length === 1) {
      return uniquePricedPerValues[0];
    }
    return null;
  }, [orderCard.selectionOptions, selectedOption]);

  const getOrderCardData = useCallback((
    formData: FormData
  ): OrderCardSubgroupOptionType["cardData"] => {
    const { ...values } = formData;

    const attributeSelections = Object.keys(values.attributeSelections)
      .map((attributeGuid) => {
        return {
          attributeGuid: attributeGuid,
          attributeselectionGuid: values.attributeSelections[attributeGuid],
        };
      })
      .filter((selection) => selection.attributeselectionGuid != null);

    const cardData: OrderCardSubgroupOptionType["cardData"] = {
      ...values,
      attributeSelections,
    };

    if (cardData.customOptionSelected) {
      cardData.selectedOptionGuid = null;
    } else {
      cardData.price = null;
      cardData.color = null;
    }

    return cardData;
  },[]);

  const {
    request: saveOrderCardRequest,
  } = useApiRequest<OrderCardEditResponse<OrderCard>>(false, "orderCard");
  const handleBlurField = () => {
    const cardData = getOrderCardData(getValues());
    const thisTimestamp = new Date().getTime();
    if (thisTimestamp > savedTimestamp) savedTimestamp = thisTimestamp;
    saveOrderCardRequest(`/ordercards/${orderCard.orderCardGuid}/edit`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      data: { 'cardData': cardData },
    }, {
      onSuccess: (data) => {
        if (savedTimestamp === thisTimestamp) {
          onOrderCardEdited(data);
          if (orderCardIndex) {
            setOrder((prevOrder) =>
              produce(prevOrder, (draft) => {
                if (!draft || !draft.orderCards) return;
                draft.orderCards[orderCardIndex] = data.orderCard;
              })
            );
          }
        }
      },
      onUnsuccess() {
        //
      } 
    });
  };

  const handleEditingField = () => {
    setEditingOrderCard(orderCard);
  };

  const handleClearClick = () => {
    const defaultValues = getDefaultValues(null);
    reset(defaultValues);
    handleBlurField();

    /*
    saveOrderCardRequest(`/ordercards/${orderCard.orderCardGuid}/clear`, {
      method: "DELETE",
    });

    const cardData = getOrderCardData(defaultValues);
    saveOrderCard(orderCard.orderCardGuid, cardData);    
    */
  };

  const handleDeleteClick = () => {
    setPendingDeletion(true);
  };

  const handleDeleteConfirmation = (data: any) => {
    setOrder((prevOrder) =>
      produce(prevOrder, (draft) => {
        if (!draft || !draft.orderCards) return;
        let index = orderCard.sortOrder;
        if (draft.orderCards.length <= index || draft.orderCards[index].orderCardGuid !== orderCard.orderCardGuid) {
          index = draft.orderCards.findIndex((dcard) => dcard.orderCardGuid === orderCard.orderCardGuid);
        }
        draft.orderCards.splice(index,1);
        if (data.orderCardSort && data.orderCardSort.length > 0) {
          for(let i=0; i<data.orderCardSort.length; i++) {
            const dindex = draft.orderCards.findIndex((dcard) => dcard.orderCardGuid === data.orderCardSort[i].orderCardGuid);
            if (dindex >= 0) draft.orderCards[dindex].sortOrder = data.orderCardSort[i].sortOrder;
          }
        }
        if (data.relatedOrderCards && data.relatedOrderCards.length > 0) {
          data.relatedOrderCards.forEach((card: OrderCard) => {
            let x2 = card.sortOrder;
            if (draft.orderCards!.length <= x2 || draft.orderCards![x2].orderCardGuid !== card.orderCardGuid) {
              x2 = draft.orderCards!.findIndex((dcard) => dcard.orderCardGuid === card.orderCardGuid);
            }
            draft.orderCards![x2] = card;
          });
        }
        draft.saveAsOptions = data.saveAsOptions;
      })
    );

    setPendingDeletion(false);
  };

  const handleDeleteOrderCard = () => {
    if (orderCard.isDeletable) {
      deleteOrderCardRequest(`/ordercards/${orderCard.orderCardGuid}/delete`, {
        method: "DELETE",
      });
    } else {
      handleClearClick();
      setPendingDenial(false);
    }
  };

  const handleDenyClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    setPendingDenial(true);
  };

  const handleSubgroupClick = () => {
    addRoomRequest(`/ordercards/${orderCard.orderCardGuid}/add`, {
      method: "POST",
      data: {
        selectedOptionGuid: selectedOptionGuid,
      },
    });
  };

  const [customOptionSelectedVisuals, setCustomOptionSelectedVisuals] = useState(customOptionSelected);
  useEffect(() => {
    const cardData = getOrderCardData(getValues());
    setCustomOptionSelectedVisuals(cardData.customOptionSelected);
  },[customOptionSelected, getOrderCardData, getValues, setCustomOptionSelectedVisuals]);

  return (
    <>
      {isLoading && <LoadingOverlay />}

      {!(orderCard?.isHidden ?? false) && (
        <OrderCardBase
          disabled={disabled}
          orderCard={orderCard}
          disclaimerText={selectedOption?.optionDisclaimer}
          subtitle={orderCardTotal}
          groupError={groupInvalid ? "Inactive" : undefined}
          subgroupError={subgroupError}
          chipLabel={customOptionSelectedVisuals ? "Custom" : undefined}
          isRequired={orderCard.isRequired}
          hasLogicError={hasLogicError}
          onClearClick={handleClearClick}
          onDeleteClick={orderCard.isDeletable ? handleDeleteClick : undefined}
          expanded={expanded}
          groupIndex={groupIndex}
          customOptionSelectedVisuals={customOptionSelectedVisuals}
          action={
            isAddCustomOptionButtonVisible && (
              <OrderCardActionButton
                onClick={handleAddCustomOptionClick}
                disabled={addCustomOptionLoading}
              >
                Custom Option
              </OrderCardActionButton>
            )
          }
          endActions={
            customOptionSelectedVisuals &&
            order.isTemplate === false &&
            orderCard.orderCardStatus === "c" &&
            orderCard.isApproved === false ? (
              <OrderCardApproveDenyButtons
                orderCard={orderCard}
                refreshOrder={refreshOrder}
                denyButtonText={user?.isRetailUser ? "Clear" : (orderCard.isDeletable ? "Delete" : "Deny")}
                onDenyClick={handleDenyClick}
                onOrderCardEdited={onOrderCardEdited}
                allUsersCanDeny={true}
              />
            ) : undefined
          }
        >
          <Grid container spacing={2} component="form">
            <Grid md={6}>
              <Controller
                control={control}
                name="selectedOptionGuid"
                render={({ field, fieldState }) => (
                  <SelectField
                    {...field}
                    label="Options"
                    onFocus={handleEditingField}
                    placeholder="Enter Options"
                    fullWidth
                    disabled={disabled}
                    options={optionsFieldOptions}
                    error={!!fieldState.error}
                    helperText={fieldState.error?.message}        
                    onChange={(e) => {
                      const newSelectedOptionGuid = e.target.value as string;
                      const isCustomOptionSelected = newSelectedOptionGuid === CustomOptionSelectOption.value;
                      setValue("customOptionSelected", isCustomOptionSelected);
                      setValue("selectedOptionGuid",newSelectedOptionGuid);
                      setValue("quantity",null);
                      setValue("selectedRooms",[]);
                      setValue("roomsAndQuantities",[]);
                      setValue("attributeSelections",{});
                      setTimeout(handleBlurField,10);
                    }}
                  />
                )}
              />
            </Grid>
            {!isRoomAndQuantity ? (
              <Grid xs={3} sm={3} md={3}>
                <InputField
                  {...register(`quantity`, {
                    onBlur: handleBlurField,
                    valueAsNumber: true,
                  })}
                  onClick={(e) => { 
                    handleBlurField();
                  }}
                  label="Quantity"
                  helperText={formState.errors["quantity"]?.message}
                  error={!!formState.errors["quantity"]?.message}
                  onFocus={handleEditingField}
                  placeholder="Enter Quantity"
                  required={customOptionSelectedVisuals}
                  fullWidth
                  disabled={disabled || (selectedOption && selectedOption.optionMinimum === selectedOption.optionMaximum)}
                  type="number"
                  endAdornment={
                    selectedOption &&
                    (selectedOption.optionPricedPer === "l" ||
                      selectedOption.optionPricedPer === "s") ? (
                      <InputAdornment position="end">
                        {`${getOptionPricePerShortDisplayName(
                          selectedOption.optionPricedPer
                        )}`}
                      </InputAdornment>
                    ) : undefined
                  }
                  intOnly={true}
                />
              </Grid>
            ) : (
              <Grid xs={3} sm={3} md={3}>
                <InputField
                  label="Quantity"
                  helperText={formState.errors["quantity"]?.message}
                  error={!!formState.errors["quantity"]?.message}
                  fullWidth
                  disabled={true}
                  type="number"
                  value={totalQuantity}
                  endAdornment={
                    selectedOption &&
                    (selectedOption.optionPricedPer === "l" ||
                      selectedOption.optionPricedPer === "s") ? (
                      <InputAdornment position="end">
                        {`${getOptionPricePerShortDisplayName(
                          selectedOption.optionPricedPer
                        )}`}
                      </InputAdornment>
                    ) : undefined
                  }
                  intOnly={true}
                />
              </Grid>
              )
            }
            <Grid xs={3} sm={3} md={3}>
              <InputField
                {...register(`price`, {
                  onBlur: () => {
                    handleBlurField();
                  },
                  valueAsNumber: true,
                })}
                label="Price"
                onFocus={handleEditingField}
                placeholder=""
                required={customOptionSelectedVisuals}
                fullWidth
                disabled={disabled || isPriceFieldDisabled}
                type="number"
                startAdornment={
                  <InputAdornment position="start">$</InputAdornment>
                }
                intOnly={true}
                endAdornment={
                  optionPricedPer && optionPricedPer !== "u" ? (
                    <InputAdornment position="end">
                      {`/${getOptionPricePerShortDisplayName(optionPricedPer)}`}
                    </InputAdornment>
                  ) : undefined
                }
              />
            </Grid>
            {isRoomsDropdownVisible && (
              <Grid xs={12} sm={12} md={12}>
                <Controller
                  control={control}
                  name="selectedRooms"
                  render={({ field, fieldState }) => (
                    <MultiSelectField
                      {...field}
                      label="Rooms"
                      onFocus={handleEditingField}
                      placeholder="Enter Rooms"
                      fullWidth
                      options={roomsFieldOptions}
                      error={!!fieldState.error}
                      helperText={fieldState.error?.message}
                      onChange={(e) => {
                        const newSelectedRooms =
                          typeof e.target.value === "string"
                            ? [e.target.value as OptionRoom]
                            : e.target.value;

                        // Make sure the order of the selected room options stored in
                        // react hook form are the same order in which they appear on the UI.
                        const newSelectedRoomsSorted = roomsFieldOptions
                          .map((option) => option.value)
                          .filter((roomOption) =>
                            newSelectedRooms.includes(roomOption)
                          );                        

                        if (isRoomAndQuantity) {
                          let newRoomsAndQuantities: {roomName: OptionRoom, quantity: number}[] = [];
                          newSelectedRooms.forEach((room) => {
                            let found = false;
                            for(let i=0; i<roomsAndQuantities.length; i++) {
                              if (roomsAndQuantities[i].roomName === room) {
                                newRoomsAndQuantities.push(roomsAndQuantities[i]);
                                found = true;
                                break;
                              }
                            }
                            if (!found) {
                              newRoomsAndQuantities.push({roomName: room, quantity: 0});
                            }
                          });
                          setValue('roomsAndQuantities', newRoomsAndQuantities);                    
                        }
                        field.onChange(newSelectedRoomsSorted);
                      }}
                      onDelete={(_, removedIndex) => {
                        const newSelectedRooms = (selectedRooms ?? []).filter(
                          (_, roomIndex) => roomIndex !== removedIndex
                        );
                        if (isRoomAndQuantity) {
                          const newRoomsAndQuantities = (roomsAndQuantities ?? []).filter(
                            (_, roomIndex) => roomIndex !== removedIndex
                          );
                          setValue('roomsAndQuantities', newRoomsAndQuantities);
                        }
                        setValue("selectedRooms", newSelectedRooms);
                        setTimeout(handleBlurField, 10);
                      }}
                      onBlur={() => {
                        handleBlurField();
                        field.onBlur();
                      }}
                      disabled={disabled}
                    />
                  )}
                />
              </Grid>
            )}
            {isRoomAndQuantity && (
              <Grid xs={12} sm={12} md={12}>
                <Box>
                  <FormControl fullWidth size="small">
                    <FormLabel component="legend">Quantity By Room</FormLabel>
                  </FormControl>
                  <Grid container spacing={2}>
                    {roomsAndQuantities.map((rq, index) => {
                      return (
                        <Grid key={index} xs={12} sm={12} md={6}>
                          <FormControlLabel
                            control={
                              <InputField
                                {...register(`roomsAndQuantities.${index}.quantity`, {
                                  valueAsNumber: true,
                                })}
                                fullWidth
                                disabled={disabled}
                                error={
                                  !!formState.errors.roomsAndQuantities?.[index]?.quantity
                                }
                                helperText={
                                  formState.errors.roomsAndQuantities?.[index]?.quantity
                                    ?.message
                                }
                                sx={{ width: "80px" }}
                                formControlSx={{ marginBottom: 0 }}
                                intOnly={true}
                                onBlur={handleBlurField}
                              />
                            }
                            label={roomsAndQuantities[index].roomName}
                            labelPlacement="start"
                            sx={{
                              border: "1px solid",
                              borderColor: "grey.300",
                              borderRadius: 2,
                              paddingX: 2,
                              paddingY: 1,
                              alignItems: "center",
                              justifyContent: "space-between",
                              margin: 0,
                              boxSizing: "border-box",
                              width: "100%",
                              "& .MuiFormControlLabel-label": {
                                flexGrow: 1,
                              },
                              "& .MuiFormControl-root": {
                                width: "auto",
                                flexShrink: 0,
                              },
                            }}
                          />
                        </Grid>
                      );
                    })}
                  </Grid>
                </Box>
              </Grid>
            )}

            {isAddSubgroupButtonVisible ? (
              <Grid xs={12} sm={12} md={12}>
                <Button
                  variant="contained"
                  color="primary"
                  startIcon={<AddCircleOutlineOutlinedIcon />}
                  sx={{ alignSelf: "flex-start" }}
                  disabled={addRoomLoading}
                  onClick={handleSubgroupClick}
                >
                  {orderCard.subgroupName}
                </Button>
              </Grid>
            ) : null}
            {isAttributesFieldsVisible &&
              attributes.map((attribute) => {
                return (
                  <Grid key={attribute.attributeGuid} xs={12} sm={12} md={12}>
                    <Controller
                      control={control}
                      name={`attributeSelections.${attribute.attributeGuid}`}
                      render={({ field }) => {
                        return (
                          <OrderCardSubgroupOptionAttributeField
                            {...field}
                            orderCard={orderCard}
                            attribute={attribute}
                            errors={orderCard.errors}
                            value={field.value ?? ""}
                            onBlur={() => {}}
                            onChange={(value) => {
                              field.onChange(value);
                              handleBlurField();
                            }}
                            disabled={disabled}
                            selectedOption={selectedOption}
                          />
                        );
                      }}
                    />
                  </Grid>
                );
              })}
            {isColorFieldVisible ? (
              <Grid xs={12} sm={12} md={12}>
                <InputField
                  {...register("color", { 
                    onBlur: () => {
                      handleBlurField();
                    }
                  })}
                  label="Color"
                  onFocus={handleEditingField}
                  placeholder="Enter Color"
                  fullWidth
                  disabled={disabled}
                  error={!!formState.errors.color}
                  helperText={formState.errors.color?.message}
                />
              </Grid>
            ) : null}
            <Grid xs={12} sm={12} md={12}>
              <InputField
                {...register("notes", { 
                  onBlur: () => {
                    handleBlurField();
                  }
                })}
                label="Notes"
                onFocus={handleEditingField}
                placeholder="Enter Notes"
                fullWidth
                required={customOptionSelectedVisuals}
                disabled={disabled}
                type="text"
                minRows={1}
                multiline
                error={!!formState.errors.notes}
                helperText={formState.errors.notes?.message}
              />
            </Grid>
          </Grid>
        </OrderCardBase>
      )}
      {pendingDeletion && (
        <ConfirmDeleteModal
          orderCard={orderCard}
          action={"delete"}
          onDelete={handleDeleteConfirmation}
          heading={`Delete ${orderCard.subgroupName}?`}
          body={
            <Typography variant="body2" color="grey.500">
              Deleting this cannot be undone. Adding it back will have to be
              through a custom option.
            </Typography>
          }
          onCancel={() => {
            setPendingDeletion(false);
          }}
        />
      )}
      {pendingDenial && (
        <ConfirmModal
          isOpen={true}
          heading={
            orderCard.isDeletable || user?.isRetailUser
              ? "Delete this option?"
              : "Deny this custom option?"
          }
          isDestructive
          confirmDisabled={deleteOrderCardLoading}
          body={
            orderCard.isDeletable ? (
              <>              
                <Typography variant="body2" color="grey.500">
                  Deleting this custom option will remove it from the group,
                  resulting in its exclusion from the quote.
                </Typography>
                <br />
                <Typography variant="body2" color="grey.500">
                  Deleting this custom option cannot be undone.
                </Typography>
              </>
            ) : (
              <>
                <Typography variant="body2" color="grey.500">                  
                  {user?.isRetailUser ? "Deleting" : "Denying"} this custom option will clear all of the Sub Group's
                  data, returning it to its default state.
                </Typography>
                <br />
                <Typography variant="body2" color="grey.500">
                {user?.isRetailUser ? "Deleting" : "Denying"} this custom option cannot be undone.
                </Typography>
              </>
            )
          }
          confirmButtonText={orderCard.isDeletable || user?.isRetailUser ? "Delete" : "Deny"}
          onConfirm={handleDeleteOrderCard}
          onClose={() => {
            setPendingDenial(false);
          }}
        />
      )}
    </>
  );
};

const getOptionOption = (option: Option, errorText?: string) => {
  return {
    label: (
      <>
        {option.optionName}
        {!!errorText ? (
          <Typography component={"span"} color="error" marginLeft={0.5}>
            {errorText}
          </Typography>
        ) : null}
      </>
    ),
    value: option.optionGuid,
  };
};
